import React, {useEffect, useRef, useState} from "react";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import CardForm from "../../api/pay/cardForm";
import * as Cfg from "../../_cfg";
import {PaymentReceived, UpdateRecurringDonation} from "../../rdx/actions";
import {useDispatch} from "react-redux";

const Stripe = ({paymentMethod,Proj,Camp,Pack,Cus,Donation}) => {
    const dispatch = useDispatch();
    const [stripePromise, SetStripePromise] = useState(null);
    const orgId = Cfg.core.o_id;
    const cardRef = useRef();
    const [isLoading, SetLoading] = useState(false);

    useEffect(() => {
        if (paymentMethod.pk) {
            SetStripePromise(loadStripe(paymentMethod.pk));
        }
    }, [paymentMethod]);

    const ProcessPayment = async ()=>{
        try {
            const paymentData = await cardRef.current.PayWithCard('ext');
            console.log(paymentData);
            if (!paymentData.payment_success) {
                throw new Error("Payment Failed");
            }
            const email_param = {
                to: Cus.email,
                from: "",
                sub: "Donation Received",
                body: "Thanks for your donation.",
            };
            const resp = await dispatch(
                PaymentReceived(
                    Donation.d,
                    paymentData.paymentObj,
                    "received",
                    "card",
                    email_param
                )
            );
            if (Pack.p === "rec" && Pack.p_i) {
                const {paymentObj} = paymentData
                const rec_param = {
                    si:paymentObj?.s_i,
                    pi:paymentObj?.pi,
                    ci:paymentObj?.ci,
                    prc_i:paymentObj?.prc_i,
                    u:paymentObj?.donor_id,
                    ui:Cus,
                    pr_t:Proj?.p?.t,
                    pr_n: Proj?.tn,
                    cp_t: Camp?.ci?.t,
                    cp_n: Camp.tn,
                    k:Pack,
                    d:Donation.d,
                }
                await dispatch(UpdateRecurringDonation(rec_param))
            }
            return { paymentSuccess: true, ...resp };
        } catch (e) {
            const email_param = {
                to: Cus.email,
                from: "",
                sub: "Donation Failed",
                body: "Your payment not received please try again.",
            };
            await dispatch(
                PaymentReceived(Donation.d, null, "failed", "card", email_param)
            );
        }
    }


    return (
        <div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="form-group">
                        <Elements stripe={stripePromise}>
                            <CardForm
                                amount={parseInt((Pack?.a || 0) * 100, 10)}
                                label={orgId}
                                ref={cardRef}
                                isRecurring={Pack?.p === "rec"}
                                package={Pack}
                                project={Proj}
                                campaign={Camp}
                                cus={Cus} // phone, name, addr, email
                                onSuccess={(obj) => {
                                    console.log("!!!!!!!!!!!!!!!! : ", obj);
                                }}
                            />
                        </Elements>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="form-group">
                        <button
                            className="btn-block btn cmpBtn mt-5"
                            disabled={isLoading}
                            onClick={ProcessPayment}
                        >
                            {isLoading ? "Processing.." : "Pay"}
                        </button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Stripe
