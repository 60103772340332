import React from "react";
import "../home/style.css";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import PageNavbar from "components/Navbars/PageNavbar.js";
import CharityFooter from "components/Footers/CharityFooter.js";

function SingleCampaign() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <PageNavbar />
      <div className="wrapper">
        <div className="section section-campaign">
          <Container>
            <Row>
              <Col sm="12" className="campaign-header">
                <img
                  alt="..."
                  className="rounded img-fluid img-raised"
                  src={require("assets/img/single-hero.png").default}
                ></img>
                <h3>Help Dugan Ashley</h3>
                <p>
                  {" "}
                  <i className="fa fa-clock" />
                  Created 3 days ago
                </p>
              </Col>
              <Col sm="12">
                <h3 className="text-info">
                  Max Glaser is organizing this fundraiser on behalf of Dugan
                  Ashley.
                </h3>
                <p>
                  Many of us know Dugan Ashley from the days of CarnikCon.
                  Others might know him for his recent reemergence to the
                  interwebs with his cautionary tales. <br /> However you've
                  been graced with Dug's presence, we can all agree that he's
                  made a lasting impact on us all. <br />
                  For those who might not know him, Dugan is a GWOT Army veteran
                  who instantly became a firearms industry icon with his
                  comical, yet informative, channel, CarnikCon. <br />
                  If you've ever seen someone store Skittles in their Magpul
                  grip, you have Dugan to thank for that.
                </p>
                <img
                  alt="..."
                  className="rounded img-fluid img-raised"
                  src={require("assets/img/camp-s.png").default}
                ></img>
                <p className="mt-5">
                  Dugan has young children, and right now they are his biggest
                  concern. Though usually Dugan is the one to go out of his way
                  to help us, he has come to us asking for help. He is currently
                  undergoing treatment at the VA after being hospitalized last
                  month, but is asking for any guidance or help in the treatment
                  of his MS. <br /> He's come to us to ask if we can help
                  prolong is health so that he can stay with his children a
                  little while longer. I am starting this Fundraiser to help
                  offset whatever financial hardships his family is facing at
                  this time. Though Dug lives a very independent life of our
                  monetary system, unfortunately this is one of the best and
                  quickest ways to help. <br />
                  In the event that he and his family do not receive this, or
                  decide to take this fundraiser down, ALL of the funds will be
                  donated to a charity of their choice. Dugan has given us all
                  so much. <br />
                  Let's step up to the plate to help our friend.
                </p>
              </Col>
            </Row>
          </Container>
        </div>

        <CharityFooter />
      </div>
    </>
  );
}

export default SingleCampaign;
