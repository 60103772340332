import React from "react";
import "../home/style.css";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function Image() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "30vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/hero.jpg").default + ")",
            }}
          ></div>
          <div className="content-center">
            <Container>
              {/* <h2 href="#" className="text-white title p-0">
                Images
              </h2> */}
            </Container>
          </div>
        </div>
        <div className="section section-image-gallery">
          <Container>
            <h3 className="mb-5 title">Foundations Work</h3>
            <Row>
              <Col sm="12" md="4">
                <div classname="thumbnail">
                  <a href="">
                    <img
                      className="mb-4"
                      alt="..."
                      src={require("assets/img/news/n1.jpg").default}
                    ></img>
                    <div classname="caption"></div>
                  </a>
                </div>
              </Col>
              <Col sm="12" md="4">
                <div classname="thumbnail">
                  <a href="">
                    <img
                      className="mb-4"
                      alt="..."
                      src={require("assets/img/news/n6.jpg").default}
                    ></img>
                    <div classname="caption"></div>
                  </a>
                </div>
              </Col>
              <Col sm="12" md="4">
                <div classname="thumbnail">
                  <a href="">
                    <img
                      className="mb-4"
                      alt="..."
                      src={require("assets/img/news/n9.jpg").default}
                    ></img>
                    <div classname="caption"></div>
                  </a>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm="12" md="4">
                <div classname="thumbnail">
                  <a href="">
                    <img
                      className="mb-4"
                      alt="..."
                      src={
                        require("assets/img/works/education/edu.jpg").default
                      }
                    ></img>
                    <div classname="caption"></div>
                  </a>
                </div>
              </Col>
              <Col sm="12" md="4">
                <div classname="thumbnail">
                  <a href="">
                    <img
                      className="mb-4"
                      alt="..."
                      src={
                        require("assets/img/works/education/edu-3.jpg").default
                      }
                    ></img>
                    <div classname="caption"></div>
                  </a>
                </div>
              </Col>
              <Col sm="12" md="4">
                <div classname="thumbnail">
                  <a href="">
                    <img
                      className="mb-4"
                      alt="..."
                      src={
                        require("assets/img/works/education/edu-4.jpg").default
                      }
                    ></img>
                    <div classname="caption"></div>
                  </a>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm="12" md="4">
                <div classname="thumbnail">
                  <a href="">
                    <img
                      className="mb-4"
                      alt="..."
                      src={
                        require("assets/img/works/water/water-2.jpg").default
                      }
                    ></img>
                    <div classname="caption"></div>
                  </a>
                </div>
              </Col>
              <Col sm="12" md="4">
                <div classname="thumbnail">
                  <a href="">
                    <img
                      className="mb-4"
                      alt="..."
                      src={
                        require("assets/img/works/water/water-3.jpg").default
                      }
                    ></img>
                    <div classname="caption"></div>
                  </a>
                </div>
              </Col>
              <Col sm="12" md="4">
                <div classname="thumbnail">
                  <a href="">
                    <img
                      className="mb-4"
                      alt="..."
                      src={
                        require("assets/img/works/water/water-4.jpg").default
                      }
                    ></img>
                    <div classname="caption"></div>
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default Image;
