import React, { useEffect, useState } from "react";
import AuiModal from "../../aui/modal";
import {PackItem} from "./option_item";

const OptionPack = ({ OnSelect, Campaign, Pack }) => {
    const [packList, SetPackList] = useState([
        { tn: -1, n: "Other", a: 0, p: "one" },
    ]);
    const [selectedPack, SelectPack] = useState(null);
    const [isShowingPackList, ShowPackList] = useState(false);

    useEffect(() => {
        if (Campaign) {
            SetPackList(
                Campaign.pk
                    ? [...Campaign.pk, { tn: -1, n: "Other", a: 0, p: "one" }]
                    : [{ tn: -1, n: "Other", a: 0, p: "one" }]
            );
        }
        else {
            SelectPack(null);
            OnSelect(null);
        }
    }, [Campaign]);
    useEffect(() => {
        SelectPack(Pack);
    }, [Pack]);

    const SelectPackHandler = (pack) => {
        OnSelect(pack);
        SelectPack(pack);
        ShowPackList(false);
    };
    return (
            <div className="col-sm-4">
                <button
                    className="btn-block btn btn-info"
                    style={{
                        fontSize: 18,
                        fontWeight: 400,
                        margin: 5,
                        padding: 16,
                        backgroundColor: selectedPack ? "#2680c2" : null,
                    }}
                    disabled={!Campaign}
                    onClick={(e) => {
                        e.preventDefault();

                        if (!Campaign) {
                            alert("Select a Campaign First");
                            return "Campaign not found";
                        }
                        ShowPackList(true);
                    }}
                >
                    <>{selectedPack?.n || "Select Package"}</>
                </button>
                <AuiModal
                    bOpen={isShowingPackList}
                    OnClose={() => ShowPackList(false)}
                    className="aui-modal"
                >
                    <div className="modal-donation">
                        <div style={{ overflow: "auto" }}>
                            {packList.map((value) => {
                                const isSelected = value?.tn === selectedPack?.tn;
                                return (
                                    <PackItem
                                        key={value.tn}
                                        item={value}
                                        isSelected={isSelected}
                                        OnSelect={SelectPackHandler}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </AuiModal>
            </div>
    );
};

export default OptionPack;
