import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {Time} from "../../api/Utils";
import {CircularProgress} from "@material-ui/core";
import {GetPubProjectCampaignList} from "../../rdx/actions";
import AuiModal from "../../aui/modal";
import {OptionItem} from "./option_item";


const OptionCampaign = ({ type, OnSelect, Campaign,Project }) => {
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const [selectedCampaign, SelectCampaign] = useState(null);
    const [isShowingCampaignList, ShowCampaignList] = useState(false);
    const [isLoading, SetLoading] = useState(false);
    const [campaignList, UpdateCampaignList] = useState([]);
    const [lastKey, SetLastKey] = useState(null);
    const dtNow = Time.Epoch(Date.now());

    const LoadCampaign = async () => {
        try {
            SetLoading(true);
            const { Items, LastEvaluatedKey } = await dispatch(GetPubProjectCampaignList({lastKey,p_id:Project.tn}));
            if (!isMounted.current)
                return null
            UpdateCampaignList(lastKey ? [...campaignList, ...Items] : Items);
            if (LastEvaluatedKey) {
                SetLastKey(LastEvaluatedKey);
            }
            SetLoading(false);

            return { msg: "ok" };
        } catch (e) {
            SetLoading(false);
            console.warn("CampaignList: LoadCampaign: Error:", e);
            return Promise.reject(e);
        }
    };

    useEffect(() => {
        if (Campaign) {
            SelectCampaign(Campaign);
        }
    }, [Campaign]);

    useEffect(() => {
        if (!Project)
        {
            SelectCampaign(null);
            OnSelect(null);
        }

        if (isMounted.current && Project)
        {
            LoadCampaign().then().catch();
        }
    }, [Project]);

    const SelectCampaignHandler = (campaign) => {
        OnSelect(campaign);
        SelectCampaign(campaign);
        ShowCampaignList(!campaign);
    };
    useEffect(()=>{
        return ()=>{
            isMounted.current = false
        }
    },[])

    return(
        <>
            <div className="col-sm-4">
                <button
                    className="btn-block btn btn-info"
                    style={{
                        fontSize: 18,
                        fontWeight: 400,
                        margin: 5,
                        padding: 16,
                        backgroundColor: selectedCampaign ? "#2680c2" : null,
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        if (isLoading) return "Loading";
                        ShowCampaignList(true);
                    }}
                    disabled={!Project}
                >
                    {isLoading ? (
                        <>
                            Loading...
                            <CircularProgress
                                color="primary"
                                size={10}
                                style={{ top: 10, right: 20, position: "absolute" }}
                            />
                        </>
                    ) : (
                        <>{selectedCampaign?.ci?.t || "Select Campaign"}</>
                    )}
                </button>
                <AuiModal
                    bOpen={isShowingCampaignList}
                    OnClose={() => ShowCampaignList(false)}
                >
                    <div
                        className="projModal"
                        style={{
                            overflowX: "hidden",
                            overflowY: "scroll",
                        }}
                    >
                        <div className={"rounded mx-3 p-3 my-4"}>
                            <button
                                className="btn-outline-danger btn"
                                style={{
                                    fontWeight: selectedCampaign ? 900 : null,
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    SelectCampaignHandler(null);
                                }}
                            >
                                <i className="fa fa-remove" style={{ fontSize: 18 }}></i> Clear
                                Selection
                            </button>
                        </div>

                        {isLoading ? (
                            <CircularProgress
                                color="primary"
                                size={200}
                                style={{ top: "30%", left: "38%", position: "absolute" }}
                            />
                        ) : (
                            campaignList.map((value) => {
                                const isExp = !!(value.te && value.te > 0 && value.te < dtNow);
                                const isDisable = !(!value.s || value.s === "act");
                                const isSelected = value?.tn === selectedCampaign?.tn;
                                if (type === "only-active" && (isExp || isDisable)) return null;

                                return (
                                    <OptionItem
                                        key={value.tn}
                                        item={value}
                                        isExp={isExp}
                                        isDisable={isDisable}
                                        isSelected={isSelected}
                                        OnSelect={SelectCampaignHandler}
                                    />
                                );
                            })
                        )}
                        <div>
                            {lastKey && (
                                <button
                                    className="btn btn-primary ml-3"
                                    style={{
                                        margin: 5,
                                        padding: 5,
                                    }}
                                    onClick={() => LoadCampaign().catch((err) => {})}
                                >
                                    Show More
                                </button>
                            )}
                        </div>
                    </div>
                </AuiModal>
            </div>
        </>
    )
}

export default OptionCampaign
