import PayPal from "./paym_paypal";
import Stripe from "./paym_stripe";

const PaymentProcess = ({paymentMethod,Proj,Camp,Pack,Cus,Donation}) => {

    return(
        <>
            {
                paymentMethod.m === "paypal" &&
                <PayPal amount={10} currency={"GBP"}/>
            }
            {
                paymentMethod.m === "card" &&
                <Stripe paymentMethod={paymentMethod} Proj={Proj} Camp={Camp} Pack={Pack} Cus={Cus} Donation={Donation}/>
            }


        </>
    )
}
export default PaymentProcess
