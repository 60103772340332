import React from "react";
import "../home/style.css";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function SingleNews4() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "50vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/news/n4.jpeg").default + ")",
            }}
          ></div>
        </div>

        <div className="section section-work">
          <Container>
            <Row>
              <Col sm="12">
                <h3 style={{ lineHeight: "35px" }}>
                  O. B. LULU-BRIGGS FOUNDATION CALLS FOR GREATER ATTENTION TO
                  BASIC NEEDS AS WORLD MARKS DAYS OF OLDER PERSONS
                </h3>
                <img
                  alt="..."
                  className="rounded img-fluid img-raised my-4"
                  src={require("assets/img/news/allnews/n3.jpg").default}
                ></img>
                <Col lg="6" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    <a>
                      The O. B. Lulu-Briggs Foundation has called for greater
                      attention to the basic needs of older persons in the
                      country as the World marked the United Nations
                      International Day of Older Persons on October 1, 2021.
                    </a>
                    <br />
                    <br />
                    <a>
                      Mrs. Ineba Tongkam, the Foundation’s Coordinator of
                      Programmes, made the call during a media programme
                      commemorating this year’s International Day of Older
                      Persons.
                    </a>
                    <br />
                    <br />
                    <a>
                      Noting the 2021 theme for the day- “Digital Equity for All
                      Ages,” underscored the growing and central importance of
                      digital literacy around the world, she also stated that
                      the disruptive effects of COVID-19 on the global economy
                      and in particular household incomes have created unique
                      challenges for vulnerable older persons who are grappling
                      with subsistence needs.
                    </a>
                    <br />
                    <br />
                    <a>
                      She called on governments and development partners in
                      Nigeria to pay more attention to the basic needs of older
                      persons as an integral part of their strategic responses
                      to vulnerable persons impacted by the COVID-19 pandemic.
                    </a>
                    <br />
                    <br />
                    <a>
                      The most current data projects that Africa’s older
                      population – 60 years and above – will grow faster than
                      any other region of the world.
                    </a>
                    <br />
                    <br />
                    <a>
                      In 2020, Nigeria had the largest number of older persons
                      in Africa (10.9 million). This is projected to triple to
                      about 33.2 million by 2050, which will be the 11th largest
                      older population in the world. This large cohort of elders
                      will require specialized health, economic and social care
                      which must be planned for today.
                    </a>

                    <a>
                      Mentioning the Foundation’s ongoing 20th year anniversary,
                      she stated that, “Since September 2001 when the Foundation
                      was established, we have taken care of poor and neglected
                      older persons providing them with comprehensive
                      healthcare, monthly feeding and stipends also on a monthly
                      basis. 600 poor, sick and neglected elders have benefitted
                      to date.
                    </a>
                  </div>
                </Col>
                <Col lg="6" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    <a>
                      In addition, ”Before the COVID-19 pandemic, we also ran a
                      recreational centre where seniors socialized every
                      weekday, were served two nutritious meals every weekday
                      and had their healthcare needs taken care of. All that is
                      now being provided to them at their homes, alongside the
                      home-bound seniors under our care for the rest of their
                      lives.”
                    </a>
                    <br />
                    <br />
                    <a>
                      She also noted that Foundation is deepening its work on
                      elders through the 50-million-Naira High Chief Dr O. B.
                      Lulu-Briggs Geriatric Endowment Fund at the Rivers State
                      University which was announced in 2019 and launched
                      earlier this year to promote healthy ageing in Nigeria
                      through support for the advancement of scientiﬁc,
                      occupational therapeutic, educational research, and
                      medical care in Geriatric Medicine, and the establishment
                      of a world-class Geriatrics Medical Unit at the Rivers
                      State University Teaching Hospital.
                    </a>
                    <br />
                    <br />
                    <a>
                      Now in its 20th year of programming, the O. B. Lulu-Briggs
                      Foundation was established by Dr Mrs Seinye O. B.
                      Lulu-Briggs to implement sustainable interventions that
                      enhance the quality of life for under-served persons.
                    </a>
                    <br />
                    <br />
                    <a>
                      It’s programming also covers Free Medical Missions, Access
                      to Clean Water and Sanitation, Education and Scholarships
                      and Micro-credit and Entrepreneurship. In September it
                      announced a yearlong celebration of its 20th anniversary,
                      which saw it embark on a massive free eye clinic in Port
                      Harcourt.
                    </a>
                    <br />
                    <br />
                    <a>
                      The next event on its yearlong celebration will be a free
                      legal clinic scheduled to hold in November.
                    </a>
                    <br />
                    <br />
                    <a>
                      In the last two decades, amongst others, the foundation
                      has provided comprehensive care for the elderly mostly in
                      Nigeria’s Niger Delta. It has also championed the need for
                      policies that provide for and protect senior citizens
                      across Nigeria.
                    </a>
                    <br />
                    <br />
                  </div>
                </Col>
              </Col>
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default SingleNews4;
