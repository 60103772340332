import React from "react";
import "./style.css";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

import { navigate } from "@reach/router";

import { connect } from "react-redux";
import * as actions from "rdx/actions";

// core components

function CrowdHero(props) {
  return (
    <>
      <div
        className="section crowd-hero"
        style={{
          backgroundImage:
            "url(" + require("assets/img/single-campaign.png").default + ")",
        }}
      >
        <Container>
          <Row className="">
            <Col lg="8" md="12">
              <h4>Send Hope to Millions</h4>
              <h2>Fundraise with Send a Little Hope</h2>
              <p style={{ fontWeight: 400 }}>
                SEND A LITTLE HOPE provides with food packs to those who can not
                afford to buy, livestock (mainly goats and sheep) to the poor
                families and widows for income generation, in rural areas. It
                also supports the orphans under its orphans sponsorship project.{" "}
              </p>
              <button
                className="btn btn-green btn-lg"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  // navigate("/crowd/campaign/create");
                  if ("init" === props.__auth.status) {
                    //
                  } else if ("loggedin" === props.__auth.status) {
                    navigate("/crowd/campaign/create/basics");
                    // navigate("/crowd/campaign/create");
                  } else {
                    navigate("/auth/register");
                  }
                }}
              >
                Start A Campaign
              </button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

// export default CrowdHero;

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(CrowdHero);
