/**
 *	@copyright	KARD..
 */

// import * as Cfg from '../../_cfg'
// import CfgCore from '../../_cfg/core'
// import {Lambda as CfgLambda} from '../../_cfg/back'

import * as AWS from '../../api/AWS'

// import {Lambda, Core} from '../../api/AWS'

// import { Time } from 'api/Utils'

import * as Cfg from './cfg'

/**
 */
export const Project_Get = ( {pr_n} ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            // console.log( 'actions/crowd: Project_Get: gs(): ', gs() )
            // console.log( 'actions/crowd: Project_Get: gs().__auth.status: ', gs().__auth.status )
            // console.log( 'actions/crowd: Project_Get: CfgCore: ', CfgCore )
            // console.log( 'actions/crowd: Project_Get: CfgLambda: ', CfgLambda )

            const aws_lambda = new AWS.Lambda( {} )

            const aws_core = new AWS.Core( {} )
            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()

            // const lambda_name = 'loggedin' === gs().__auth.status ? d( Cfg.Lambda('app') ) : d( Cfg.Lambda('unauth') )
            const lambda_name = d( Cfg.Lambda('unauth') )

            // console.log( 'actions/crowd: Project_Get: lambda_name: ', lambda_name )

            const p_get = {
                actType: 'proj',
                act: 'proj:details',
                c_id: gs().__cfg.oId,

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: { tn: pr_n, },
            }

            console.log( 'actions/crowd: Project_Get: p_get: ', p_get )

            const resp_get = await aws_lambda.Invoke( p_get, lambda_name )

            console.log( 'actions/crowd: Project_Get: resp_get: ', resp_get )

            return resp_get

            // const mapped_data = resp_list.Items.map( x => {
            //     try
            //     {
            //         return {
            //             ...x,
            //             // k: {...x.k, a: x.k.a / 100.0},
            //         }
            //     }
            //     catch( err )
            //     {
            //         return x
            //     }
            // } )

            // //
            // return {...resp_list, Items: mapped_data}
        }
        catch( err )
        {
            console.warn( 'rdx.Project_Get: err: ', err )

            return Promise.reject( err )
        }
    }   // ...
}   // Project_Get

/**
 */
export const Project_List = ( {last_key, n_elem = 10} ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            console.log( 'actions/crowd: Project_List: gs(): ', gs() )
            console.log( 'actions/crowd: Project_List: gs().__auth.status: ', gs().__auth.status )
            // console.log( 'actions/crowd: Project_List: CfgCore: ', CfgCore )
            // console.log( 'actions/crowd: Project_List: CfgLambda: ', CfgLambda )

            const aws_lambda = new AWS.Lambda( {} )

            const aws_core = new AWS.Core( {} )
            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()

            // const lambda_name = 'loggedin' === gs().__auth.status ? d( Cfg.Lambda('app') ) : d( Cfg.Lambda('unauth') )
            const lambda_name = d( Cfg.Lambda('unauth') )

            console.log( 'actions/crowd: Project_List: lambda_name: ', lambda_name )

            const p_list = {
                actType: 'crowd',
                act: 'crowd:list',
                c_id: gs().__cfg.oId,

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    last_key,
                    // index,
                    // dt_st,
                    // dt_end,
                    // pr_n,
                    // cp_n,
                    n_elem,
                },
            }

            console.log( 'actions/crowd: Project_List: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, lambda_name )

            console.log( 'actions/crowd: Project_List: resp_list: ', resp_list )

            const mapped_data = resp_list.Items.map( x => {
                try
                {
                    return {
                        ...x,
                        // k: {...x.k, a: x.k.a / 100.0},
                    }
                }
                catch( err )
                {
                    return x
                }
            } )

            //
            return {...resp_list, Items: mapped_data}
        }
        catch( err )
        {
            console.warn( 'rdx.Project_List: err: ', err )

            return Promise.reject( err )
        }
    }   // ...
}   // Project_List

/**
 */
export const Campaign_Get = ( {pr_n, cp_n} ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            // console.log( 'actions/crowd: Campaign_Get: gs(): ', gs() )
            // console.log( 'actions/crowd: Campaign_Get: gs().__auth.status: ', gs().__auth.status )
            // console.log( 'actions/crowd: Campaign_Get: CfgCore: ', CfgCore )
            // console.log( 'actions/crowd: Campaign_Get: CfgLambda: ', CfgLambda )

            const aws_lambda = new AWS.Lambda( {} )

            const aws_core = new AWS.Core( {} )
            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()

            // const lambda_name = 'loggedin' === gs().__auth.status ? d( Cfg.Lambda('app') ) : d( Cfg.Lambda('unauth') )
            const lambda_name = d( Cfg.Lambda('unauth') )

            // console.log( 'actions/crowd: Campaign_Get: lambda_name: ', lambda_name )

            const p_get = {
                actType: 'camp',
                act: 'camp:details',
                c_id: gs().__cfg.oId,

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    pr_n,
                    tn: cp_n,
                },
            }

            // console.log( 'actions/crowd: Campaign_Get: p_get: ', p_get )

            const resp_get = await aws_lambda.Invoke( p_get, lambda_name )

            // console.log( 'actions/crowd: Campaign_Get: resp_get: ', resp_get )

            resp_get.Item.pk = resp_get.Item.pk.map( x => ({...x, a: x.a / 100.0}) )

            return {...resp_get}
        }
        catch( err )
        {
            console.warn( 'rdx.Campaign_Get: err: ', err )

            return Promise.reject( err )
        }
    }   // ...
}   // Campaign_Get

/**
 */
export const Campaign_List = ( {last_key, n_elem = 10} ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            console.log( 'actions/crowd: Campaign_List: gs(): ', gs() )
            console.log( 'actions/crowd: Campaign_List: gs().__auth.status: ', gs().__auth.status )
            // console.log( 'actions/crowd: Campaign_List: CfgCore: ', CfgCore )
            // console.log( 'actions/crowd: Campaign_List: CfgLambda: ', CfgLambda )

            const aws_lambda = new AWS.Lambda( {} )

            const aws_core = new AWS.Core( {} )
            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()

            // const lambda_name = 'loggedin' === gs().__auth.status ? d( Cfg.Lambda('app') ) : d( Cfg.Lambda('unauth') )
            const lambda_name = d( Cfg.Lambda('unauth') )

            console.log( 'actions/crowd: Campaign_List: lambda_name: ', lambda_name )

            const p_list = {
                actType: 'crowd',
                act: 'crowd:list',
                c_id: gs().__cfg.oId,

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    last_key,
                    // index,
                    // dt_st,
                    // dt_end,
                    // pr_n,
                    // cp_n,
                    n_elem,
                },
            }

            console.log( 'actions/crowd: Campaign_List: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, lambda_name )

            console.log( 'actions/crowd: Campaign_List: resp_list: ', resp_list )

            const mapped_data = resp_list.Items.map( x => {
                try
                {
                    return {
                        ...x,
                        // k: {...x.k, a: x.k.a / 100.0},
                    }
                }
                catch( err )
                {
                    return x
                }
            } )

            //
            return {...resp_list, Items: mapped_data}
        }
        catch( err )
        {
            console.warn( 'rdx.Campaign_List: err: ', err )

            return Promise.reject( err )
        }
    }   // ...
}   // Campaign_List

