import { actionRow } from "aws-amplify"

/**
 *	@copyright	KARD.
 */

/**
 */
export default ( state = {
    isLoading: false,
    list: [],
    last: null,
}, action ) =>
{
    switch( action.type )
    {
    case 'crowd:is_loading':
        return {...state, is_loading: action.payload}
    case 'crowd:list':
        return {...state, list: action.payload.list, last: action.payload.last}

    default:
        return state
    }   // switch ...
}   // export



