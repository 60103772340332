import React, {useEffect, useRef, useState} from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Input,
  FormGroup,
} from "reactstrap";

// core components

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import NarrowFooter from "components/Footers/NarrowFooter.js";
import {ImageInput} from "../_common/ui_components";

import {useDispatch} from "react-redux";
import {UpdateCrowdCampaign} from "../../rdx/actions";

import Image from "aui/image";

/**
 */
function CrowdCampaignEdit(props)
{
  const dispatch = useDispatch()
  const uid = decodeURIComponent(props.uid);
  const camp = props.location.state;

  // const imgReg = useRef()

  const [imgUrl, SetImgUrl] = useState(camp.ct.img?.c?.f);
  const [title, SetTitle] = useState(camp.t);
  const [tagLine, SetTagLine] = useState(camp.tl);
  const [amount, SetAmount] = useState(parseInt(camp.a.t/100,10).toString());
  const [doc,SetDoc] = useState(null);

  const [listDesc, ListDesc] = React.useState( camp.ct.listDesc ? camp.ct.listDesc : [
    { t: 'txt', d: '', tn: Date.now(), },
  ] );

  const [ct, UpdateCt] = useState(camp?.ct)

  /**
   */
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  React.useEffect(() => {
    console.log( 'CrowdCampaignEdit: camp: ', camp )
    // console.log( 'CrowdCampaignEdit: uid: ', uid )
    console.log( 'CrowdCampaignEdit: listDesc: ', listDesc )
  }, [ct,listDesc]);

  /**
   */
  async function UpdateCampaign(event)
  {
    event.preventDefault()
    try {
      const upd_p = {
        ta:parseInt(amount*100,10),
        t:title,
        tl:tagLine,
        i:camp.i,
        id:camp.id,
        uid:camp.uid,
        isImgChange:false,
          ct,
          listDesc
      }
      if (doc?.file)
      {
        upd_p['file'] = doc.file
        upd_p['isImgChange'] =true
      }

      const resp = await dispatch(UpdateCrowdCampaign(upd_p))

      // console.log(resp)

      if( resp )
      {
        //
      }
    }
    catch (e)
    {
      console.warn(e)
      return e
    }
  } // UpdateCampaign


  /**
   */
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="section section-campaign campaign-single">
          <Container>
            <Row>
              <Form onSubmit={UpdateCampaign}>
                <Col sm="12" className="create-campaign">
                  <div className="row">
                    <Col sm="12" className="campaign-sumamry">
                      {/* <img
                        alt="Profile Image"
                        className="responsive"
                        style={{
                          width: "100%",
                          minHeight: 400,
                          maxHeight: 400,
                          objectFit: "cover",
                        }}
                        src={imgUrl}
                      /> */}
                      <Image
                        src={imgUrl}
                        alt={require("assets/img/placeholder.png").default}
                        cn="responsive"
                        style={{
                          width: "100%",
                          minHeight: 400,
                          maxHeight: 400,
                          objectFit: "cover",
                        }}
                      />
                     <ImageInput onChange={d=>{
                       SetDoc(d)
                       SetImgUrl(d.path)
                     }}/>
                      <h4> Campaign Title</h4>
                      <Input
                        className="form-control mb-2"
                        type="text"
                        onChange={event => {
                          SetTitle(event.target.value)
                        }}
                        value={title}
                      />

                      <h4> Campaign Tagline</h4>
                      <textarea
                        style={{ border: "1px solid #e3e3e3" }}
                        className="form-control p-2"
                        id=""
                        rows="3"
                        onChange={event => {
                          SetTagLine(event.target.value)
                        }}
                        value={tagLine}
                      />

                      <h4> Target Amount</h4>
                      <p>
                        <Input
                          className="form-control"
                          type="text"
                          onChange={event => {
                            SetAmount(event.target.value)
                          }}
                          value={amount}
                        />
                      </p>

                      <>
                      {
                        listDesc.map( (x,i,a) => (
                          <DescComp
                            key={x.tn} {...x}
                            OnChange={(t, d) => {
                                a[i].t = t
                                a[i].d = d
                                ListDesc([...a]);

                            }}
                            OnRem={() => {
                              // console.log( 'OnRem: i: ', i )
                              // a.splice(i, 1);
                              // // console.log( 'OnRem: a: ', a )
                              // ListDesc([...a]);
                            }}
                          />
                        ) )
                      }
                      </>

                      <Button
                        className="btn btn-primary"
                        for="btn-check"
                        style={{ background: "#A3ACB8" }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          ListDesc([
                            ...listDesc,
                            { t: 'txt', d: '', tn: Date.now(), },
                          ]);
                        }}
                      >
                        Add Section
                      </Button>

                      <FormGroup className="form-inline">
                        <i className="fab fa-facebook btn btn-green" />
                        <Input
                          className="col-sm-10"
                          id="social"
                          placeholder="http://"
                          type="text"
                          value={ct?.socFb}
                          onChange={ e => {
                              e.preventDefault()
                              UpdateCt(prev=>{
                                  prev['socFb']=e.target.value
                                  return ({...prev})
                              })
                          } }
                        />
                      </FormGroup>

                      <FormGroup className="form-inline">
                        <i className="fab fa-instagram btn btn-green" />
                        <Input
                          className="col-sm-10"
                          id="social"
                          placeholder="http://"
                          type="text"
                          value={ct?.socInsta}
                          onChange={ e => {
                              e.preventDefault()
                              UpdateCt(prev=>{
                                  prev['socInsta']=e.target.value
                                  return ({...prev})
                              })
                          } }
                        />
                      </FormGroup>

                      <FormGroup className="form-inline">
                        <i className="fab fa-twitter btn btn-green" />
                        <Input
                          className="col-sm-10"
                          id="social"
                          placeholder="http://"
                          type="text"
                          value={ct?.socTwit}
                          onChange={ e => {
                              e.preventDefault()
                              UpdateCt(prev=>{
                                  prev['socTwit']=e.target.value
                                  return ({...prev})
                              })
                          } }
                       />
                      </FormGroup>

                      <FormGroup className="form-inline">
                        <i className="fab fa-youtube btn btn-green" />
                        <Input
                          className="col-sm-10"
                          id="social"
                          placeholder="http://"
                          type="text"
                          value={ct?.socYT}
                          onChange={ e => {
                              e.preventDefault()
                              UpdateCt(prev=>{
                                  prev['socYT']=e.target.value
                                  return ({...prev})
                              })
                          } }
                       />
                      </FormGroup>
                    </Col>

                  </div>

                  <div className="btn-group">
                    <Button
                      className="mt-2 btn-lg text-center mr-2"
                      color="primary"
                      type="submit"
                    >
                      Update
                    </Button>
                  </div>
                </Col>
              </Form>
            </Row>
          </Container>
        </div>
        <NarrowFooter />
      </div>
    </>
  );
}

export default CrowdCampaignEdit;

/**
 */
/**
 */
const DescComp = (props) => {
  const [type, Type] = React.useState(props.t); // txt / img / vidUrl
  const [text, Text] = React.useState('txt' === props.t?props?.d:'');

  const imgRef = useRef();
  const [imgDoc, ImgDoc] = React.useState('img' === props.t && props.d ? props.d : {});

  const OnChangeTxt = (e) => {
      // e.preventDefault()
    Text(e.target.value);
    props.OnChange('txt', e.target.value);
  };

  // useEffect(()=>{
  //     if ('txt' === props.t)
  //     {
  //        Text(props.t)
  //     }
  //     console.log(text)
  // },[])

  // const OnBlur = e => {
  //   props.OnChange(desc);
  // }

  const HandleFileRead = ( event ) => {
    // const { name, value } = event.target;
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);

    const doc = {
      file: file,
      type: file.type,
      name: file.name,
      url: url,
    };

    if (!file) return { msg: "Image not found" };

    ImgDoc({ ...doc });
    props.OnChange('img', doc);
  };

  return (
    <>
      <>
        {"txt" === type ? (
          <textarea
            // key={i}
            className="form-control col-sm-8 mt-3"
            style={{
              maxWidth: "90%",
              border: "1px solid #e3e3e3",
              borderRadius: 4,
            }}
            rows="20"
            col="50"
            id={["social", props.key].join("-")}
            placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, "
            value={text}
            onChange={OnChangeTxt}
            // onBlur={OnBlur}
          />
        ) : "img" === type ? (
          <div>
            <Image
              src={imgDoc.url}
              alt={require("assets/img/placeholder.png").default}
              altTxt="image"
              cn="border"
              style={{
                width: 400,
                maxHeight: 300,
                minHeight: 300,
                objectFit: "cover",
              }}
            />
            <Button
              className="btn btn-primary input-group-append"
              for="btn-check"
              style={{ background: "#A3ACB8" }}
              onClick={(e) => {
                e.preventDefault();
                imgRef.current.click();
              }}
            >
              {/* Image */}
              Select
            </Button>
            <input
              type="file"
              accept="image/png, image/jpeg"
              style={{ display: "none" }}
              id="contained-button-file"
              ref={imgRef}
              onChange={HandleFileRead}
            />
          </div>
        ) : "vid" === type ? (
          <div>{/*  */}</div>
        ) : null}
      </>

      <div className="mt-3 col-sm-11 btn-group p-0">
        <button
          className="btn btn-green mr-1"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            Type("txt");
          }}
        >
          Text
        </button>
        <button
          className="btn btn-green mr-1"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            Type("img");
          }}
        >
          Image
        </button>
        <button
          className="btn btn-green mr-1"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            Type("vid");
          }}
        >
          Video
        </button>
        <button
          className="btn btn-danger"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.OnRem();
          }}
        >
          <i className="fa fa-trash text-white" /> Remove
        </button>
      </div>
    </>
  );
}; // DescComp


