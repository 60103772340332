import React from "react";
import "../home/style.css";
// reactstrap components
import { navigate } from "@reach/router";
import { Container, Row, Col } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function EducationScholarship() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "50vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" +
                require("assets/img/works/education/edu-3.jpg").default +
                ")",
            }}
          ></div>

          <div className="content-center">
            <Container>
              <h3 href="#" className="text-white ">
                Education
              </h3>
              {/* <a
                className="btn btn-lg text-white pt-3 cmpBtn"
                color="info"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  navigate("/donation/ext/1642076846/1642077285182");
                }}
              >
                Donate
              </a> */}
            </Container>
          </div>
        </div>

        <div className="section">
          <Container>
            <Row>
              <Col sm="9" className="mx-auto">
                <p className="workParagraph my-3">
                  Our Education and Scholarships Programme helps to broaden
                  access to and promote achievement in education especially for
                  students who would otherwise be deprived of this opportunity
                  due to financial constraints. We award scholarships to
                  students from primary to university levels, thereby catering
                  for education at all stages of a person’s life. We also
                  provide standard conducive learning environments in urban and
                  rural areas by building and equipping schools with the
                  infrastructure and resources needed to thrive.
                </p>
                <p className="workParagraph my-3">
                  Through this programme we are contributing to SDG 4 which
                  seeks to ensure inclusive and equitable quality education and
                  promote lifelong learning opportunities for all. Education
                  remains a widespread issue in Nigeria. Although primary
                  education is free and compulsory, approximately 10.5 million
                  of the country’s children aged 5-14 years are not in school.
                  Data suggests that only 61% of 6-11 year-olds regularly attend
                  primary school. Gender also plays a role in accessing quality
                  education as young girls often face cultural barriers to
                  education. Socio-cultural norms and practice discourage
                  attendance in formal education particularly for girls which
                  can have a massive impact on women later in life from
                  accessing employment, training, and livelihood opportunities.
                  Therefore, interventions which seek to reduce educational
                  inequality are of upmost importance.
                </p>

                <img
                  alt="..."
                  className="rounded img-fluid center-img my-5"
                  src={require("assets/img/works/education/edu.jpg").default}
                ></img>

                <h3>CONDUCIVE LEARNING ENVIRONMENTS</h3>
                <p className="mt-3">
                  One way in which we support and encourage educational
                  excellence is through building and equipping schools. We have
                  done this for the science laboratories in Abonnema Rivers
                  State Girls’ Secondary School, Nyemoni Grammar School and
                  Comprehensive Secondary School, Abonnema Rivers State. In
                  addition, we also built a model nursery and primary school
                  furnished with modern facilities including a computer lab for
                  St. Augustine’s Nursery & Primary School, Abonnema Rivers
                  State.
                </p>

                <img
                  alt="..."
                  className="rounded img-fluid center-img my-5"
                  src={require("assets/img/works/education/edu-2.jpg").default}
                ></img>
                <img
                  alt="..."
                  className="rounded img-fluid center-img my-5"
                  src={require("assets/img/works/education/edu-5.jpg").default}
                ></img>

                <h3>IMPROVING ACCESS TO TERTIARY EDUCATION</h3>
                <p className="">
                  In 2017, the Foundation commissioned a fully equipped IT
                  centre for learning where tutors prepare rural youth to
                  compete better with their urban counterparts in gaining access
                  to tertiary education. National and international examinations
                  including WAEC, NECO, and JAMB are covered here. The
                  Foundation funds both the teaching and the examination fees in
                  order to support these students, with 226 youth benefitting.
                  An impressive 70% passed the 2017/2018 and 2018/2019
                  examination. We hope to expand this initiative and create
                  similar opportunities for youth around the world
                </p>
                {/* <a
                  className="btn btn-lg text-white pt-3 cmpBtn"
                  color="info"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    navigate("/donation/ext/1642076846/1642077285182");
                  }}
                >
                  Donate
                </a> */}

                {/* <button
                  type="button"
                  className="btn btn-primary btn-lg display-block"
                >
                  EDUCATION AND SCHOLARSHIPS
                  <span className="badge badge-light">1326</span>
                </button> */}
              </Col>
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default EducationScholarship;
