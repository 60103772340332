/**
 *  @copyright KARD
 */

import React from "react";

import { Router, navigate } from "@reach/router";

import { connect, useDispatch } from "react-redux";
import * as actions from "rdx/actions";

// import {MLoader} from '../../aui/loader'

import AuthLogin from "views/auth/login";
// import AuthLock from '../auth/lock'
// import AuthChange from '../auth/pw_change'
// import AuthComplete from '../auth/pw_complete'
import AuthRegister from "views/auth/register";
import AuthReset from "views/auth/reset";
// import AuthRegister2 from 'views/auth/register2'
import AuthForgotten from "views/auth/forgotten";

import Home from "views/home";
// import Profile from "views/profile";
// import Projects from '../projects'

// Crowd
import Crowd from "views/crowd";
import CrowdCampaignEdit from "views/crowd/campaign_edit";
import CrowdCampaignAll from "views/crowd/campaign_all";

// import CrowdCampaign from "views/crowd_campaign";
import CrowdCampaignDetails from "views/crowd/campaign";
import CrowdCampaign_Create from "views/crowd_campaign/create";
import CrowdCampaign_Create_Basic from "views/crowd_campaign/create_basic";
import CrowdCampaign_Create_Funding from "views/crowd_campaign/create_funding";
import CrowdCampaign_Create_Category from "views/crowd_campaign/create_category";
import CrowdCampaign_Create_Content from "views/crowd_campaign/create_content";
import CrowdCampaign_Create_Summary from "views/crowd_campaign/create_summary";
import CrowdCampaign_Create_Thanks from "views/crowd_campaign/create_thanks";

import Campaign from "views/campaign";
import All_Campaign from "views/campaign/all_campaign";
import Single_Campaign from "views/campaign/single";
// import Campaign_Start from "views/campaign/start";
// import Campaign_Edit from "views/campaign/edit";

// Profile

// import UserProfile from "views/profile/";

// // Donation
// // import Donation from "views/donation";
// import Donation from "views/donation";
// import CrowdDonation from "views/donation/crowd_donation";
// import DonationSuccess from "views/donation/donation_success";

import AllWork from "views/work";
import CareForLife from "views/work/care-for-life";
import HighChief from "views/work/high-chief-ob";
import FreeMedicalMission from "views/work/free-medical-mission";
import EducationScholarship from "views/work/education-scholarship";
import MicroCredit from "views/work/micro-credit";
import PortableWater from "views/work/portable-water";
import Covid from "views/work/special-intervensions";
import Humanitarian from "views/work/humanitarian-interventions";
import HealthAwareness from "views/work/health-awareness";
import LawSchool from "views/work/law-school";

// Pages
import Team from "views/pages/our-team";
import About from "views/pages/about";
import Image from "views/pages/image";
import Video from "views/pages/video";
import Volunteer from "views/pages/volunteer";
import GrandBenefactor from "views/pages/grand-benefactor";
import PrivacyPolicy from "views/pages/privacy_policy";
import Contact from "views/pages/contact";

// Profile
import UserProfile from "views/profile/";

// Donation
import Donation from "views/donation";
import CrowdDonation from "views/donation/crowd_donation";
import DonationSuccess from "views/donation/donation_success";

// News
import News from "views/news";
import SingleNews from "views/news/news-single";
import SingleNews1 from "views/news/n1";
import SingleNews2 from "views/news/n2";
import SingleNews3 from "views/news/n3";
import SingleNews4 from "views/news/n4";
import SingleNews5 from "views/news/n5";
import SingleNews6 from "views/news/n6";

// import Campaign from '../camp../crowd/start_edit

// import Shop from '../shop'

//
// import P500 from '../_common/p500'
import P404 from "views/_common/p404";
import { GetCharityInfo } from "rdx/actions";

/**
 */
const AppNav = (props) => {
  /**
   */
  const dispatch = useDispatch();
  React.useEffect(() => {
    const promise = [VerifyAuth(), getCharityInfo()];
    const resp = Promise.all(promise);
    // VerifyAuth()
    //   .then(() => {
    //     // props.Campaign_TypeList().catch( err => {} )
    //   })
    //   .catch(console.warn);
  }, []);

  /**
   */
  const VerifyAuth = async () => {
    try {
      const bLoggedin = await props.Auth_Verify();

      // console.log( 'AppNav: VerifyAuth: bLoggedin: ', bLoggedin )

      return bLoggedin;
    } catch (err) {
      console.warn("AppNav: VerifyAuth: err: ", err);

      return Promise.reject(err);
    }
  }; // VerifyAuth

  const getCharityInfo = async () => {
    try {
      await dispatch(GetCharityInfo());
    } catch (e) {
      console.warn(e);
      return Promise.reject(e);
    }
  };

  /**
   */
  return (
    <Router>
      <Home path="/" />
      <Home path="/home" />

      <Crowd path="/crowd" />
      <CrowdCampaignEdit path="/crowd/campaign/edit/:uid" />
      <CrowdCampaignAll path="/crowd/campaign/all" />
      <CrowdCampaignDetails path="/crowd/campaign/:uid" />

      <CrowdCampaign_Create path="/crowd/campaign/create" />
      {/* <CrowdCampaign_Create path="/crowd/campaign/create/:pr_n/:cp_n" /> */}
      <CrowdCampaign_Create_Basic path="/crowd/campaign/create/basics" />
      <CrowdCampaign_Create_Basic path="/crowd/campaign/create/basics/:pr_n/:cp_n" />
      <CrowdCampaign_Create_Category path="/crowd/campaign/create/category" />
      <CrowdCampaign_Create_Funding path="/crowd/campaign/create/funding" />
      <CrowdCampaign_Create_Content path="/crowd/campaign/create/content" />
      <CrowdCampaign_Create_Summary path="/crowd/campaign/create/summary" />
      <CrowdCampaign_Create_Thanks path="/crowd/campaign/create/thanks" />

      {/* <Projects path='/projects' /> */}
      <Donation path="/donation" />
      <DonationSuccess path="/donation/success" />
      {/* <Donation path="/donation/nowandnow" /> */}
      <Donation path="/donation/ext" />
      <Donation path="/donation/ext/:pr_n" />
      <Donation path="/donation/ext/:pr_n/:cp_n" />
      <Donation path="/donation/ext/:pr_n/:cp_n/:pck_n" />
      <CrowdDonation path="/donation/crowd/:uid" />
      <CrowdDonation path="/donation/crowd/:uid/:pck" />

      <Campaign path="/campaign" />
      <All_Campaign path="/all_campaign" />
      <Single_Campaign path="/campaign/single" />
      {/* <Campaign_Start path="/campaign/start" />
      <Campaign_Edit path="/campaign/edit" /> */}

      {/* Works */}
      <AllWork path="/work" />
      <CareForLife path="/work/care-for-life" />
      <HighChief path="/work/high-chief-ob--lulu-briggs" />
      <FreeMedicalMission path="/work/free-medical-mission" />
      <EducationScholarship path="/work/education-scholarship" />
      <MicroCredit path="/work/micro-credit" />
      <PortableWater path="/work/portable-water" />
      <Covid path="/work/special-intervensions" />
      <Humanitarian path="/work/humanitarian-intervensions" />
      <HealthAwareness path="/work/health-awareness" />
      <LawSchool path="/work/law-school" />
      {/* Pages */}
      <Team path="/about/our-team" />
      <About path="/about/us" />
      <Image path="about/image" />
      <Video path="about/video" />
      <Volunteer path="about/volunteer" />
      <GrandBenefactor path="/about/grand-benefactor" />
      <PrivacyPolicy path="/about/privacy-policy" />
      <Contact path="/about/contact" />

      <News path="/news" />
      <SingleNews path="news/news-single" />
      <SingleNews path="news/:tn" />
      <SingleNews1 path="news/n1" />
      <SingleNews2 path="news/n2" />
      <SingleNews3 path="news/n3" />
      <SingleNews4 path="news/n4" />
      <SingleNews5 path="news/n5" />
      <SingleNews6 path="news/n6" />

      <UserProfile path="/profile" />
      <AuthLogin path="/" />
      <AuthLogin path="/auth" />
      <AuthLogin path="/auth/login" />
      {/* <AuthLock path='/auth/lock' /> */}
      {/* <AuthChange path='/auth/change' /> */}
      {/* <AuthComplete path='/auth/complete' /> */}
      <AuthRegister path="/auth/register" />
      <AuthReset path="/auth/reset" />
      {/* <AuthRegister2 path='/auth/register2' /> */}
      <AuthForgotten path="/auth/forgotten" />

      <P404 default />
    </Router>
  );
}; // AppNav

// export default AppNav

/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(AppNav);
