
import IndexNavbar from "../../components/Navbars/IndexNavbar";
import React, {useEffect, useRef, useState} from "react";
import {Col, Container, Form} from "reactstrap";
import OptionProject from "./option_project";
import OptionCampaign from "./option_campaign";
import OptionPack from "./option_pack";
import DonorInfo from "./donor_info";
import PaymentMethod from "./payment_method";
import Consent from "./consent";
import Message from "./message";
import Loader from "react-js-loader";
import {PrepParams} from "./process_donation";
import {useDispatch} from "react-redux";
import {DonationNew, Donor_New, GetStripePK} from "../../rdx/actions";
import {EmailSubscribe} from "../../rdx/actions/comm";
import AuiModal from "../../aui/modal";
import PaymentProcess from "./payment_process";


const Donation = ({}) => {
    const dispatch = useDispatch();
    const [isLoading, SetLoading] = useState(false);
    const [errorMessage, SetError] = useState(null);
    const [project, SetProject] = useState(null);
    const [campaign, SetCampaign] = useState(null);
    const [pack, SetPack] = useState(null);
    const [donorInfo, SetDonorInfo] = useState(null);
    const [paymentMethod, SetPaymentMethod] = useState(null);
    const [giftAid, SetGiftAid] = useState(false);
    const [consent, SetConsent] = useState(false);
    const [isAnonymous, SetAnonymous] = useState(false);
    const [message, SetMessage] = useState("");
    const [showPaymentView, ShowPaymentView] = useState(false);
    const [donation, SetDonation] = useState(null)

    useEffect(()=>{
        if (paymentMethod)
            ShowPaymentView(true)
    },[paymentMethod])

    const ProcessDonation = async (e) => {
      try {
          if (e) {
              e.preventDefault();
              e.stopPropagation();
          }
          SetLoading(true);
          ShowPaymentView(true);
          return null;

          const {donorParam,donationParams,emailParams,subsParams} = PrepParams({project,campaign,pack,donorInfo,paymentMethod,giftAid,consent,isAnonymous,message})
          console.log(donationParams);
          const promise = [
              dispatch(Donor_New(donorParam))
          ];
          if (subsParams && consent){
              promise.push(dispatch(EmailSubscribe(subsParams)))
          }
          Promise.all(promise).then(data=>console.log(data)).catch();

          const resp_dont = await dispatch(DonationNew(donationParams));
          console.log(
              "Donations_Internal_New: NewDonation: resp_dont: ",
              resp_dont
          );

          if (!resp_dont.Item)
          {
              throw new Error("Donation Filed")
          }

          SetDonation(resp_dont.Item)
          ShowPaymentView(true)



      }catch (e) {
          console.warn("donation/index: Donation:ProcessDonation: error:",e);
      }
    }

    const UpdateConsent = (type) => {
        if (type === 'gift') {
            SetGiftAid(!giftAid);
        } else {
            SetConsent(!consent)
        }
    }

    return(
        <>
            <IndexNavbar/>
            <div className={"wrapper"} style={{background:"#fff"}}>
                <Container>
                    <Col sm="12" className="text-center my-5 mt-5">
                        <h2 className="mt-5" style={{ color: "#2680c2", paddingTop: 20 }}>
                            Process Donation
                        </h2>
                    </Col>
                    <Form onSubmit={ProcessDonation} className={"donationForm"}>
                        <Col sm="12">
                            <div
                                style={{
                                    backgroundColor: "#f3f3f3",
                                    padding: "16px",
                                }}
                            >
                                <div className="row">
                                    <OptionProject type={"only-active"} Project={project} OnSelect={val=>SetProject(val)}/>
                                    <OptionCampaign type={"only-active"} Project={project} Campaign={campaign} OnSelect={val=>SetCampaign(val)}/>
                                    <OptionPack Campaign={campaign} OnSelect={val=>SetPack(val)} Pack={pack}/>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label>Amount</label>
                                            {
                                                pack?.n?.toLowerCase() === "other" ?
                                                    <input
                                                        className="form-control"
                                                        value={pack?.a || ''}
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            SetPack({...pack, a: e.target.value});
                                                        }}
                                                    />
                                                    :

                                                    <div className="form-control">
                                                        £ {pack?.a}
                                                        {pack?.p === "rec" ? "/month" : ""}
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div>
                                            {pack?.p === "rec" ? (
                                                <label className="btn btn-primary">Recurring</label>
                                            ) : (
                                                <label
                                                    className="btn btn-sm text-white cmpBtn"
                                                    style={{ marginTop: 19 }}
                                                >
                                                    Once
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <DonorInfo info={donorInfo} OnChange={inf=>SetDonorInfo(inf)}/>
                        <PaymentMethod onChange={val=>SetPaymentMethod(val)} selected={paymentMethod}/>
                        <Consent OnUpdate={UpdateConsent}/>
                        <Message message={message} UpdateMessage={val=>SetMessage(val)} />
                        <div className="col-sm-12">
                            <label className="form-check-label ml-4 mt-3">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="anonymous"
                                    name="anonymous"
                                    checked={isAnonymous}
                                    onChange={(_) => SetAnonymous((ps) => !ps)}
                                />
                                <span className="form-check-sign"></span>Donate anonymously
                            </label>
                        </div>

                        <div className="col-sm-12">
                            <div className="form-group">
                                <button
                                    type="submit"
                                    className="btn-block btn cmpBtn mt-5"
                                    disabled={isLoading}
                                >
                                    {isLoading ? "Processing.." : "Process Donation"}
                                </button>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group"/>
                        </div>
                    </Form>
                </Container>
            </div>
            <>
                {(isLoading) && (
                    <div>
                        <div
                            className="modal"
                            style={{
                                // margin: '0 auto'
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                background: "#d1cccc80",
                            }}
                        >
                            <Loader
                                type="box-rectangular"
                                bgColor={"#2680c2"}
                                title={"Loading ..."}
                                color={"#2680c2"}
                                size={150}
                            />
                        </div>
                    </div>
                )}
            </>

            <>
                <AuiModal
                    bOpen={showPaymentView}
                    OnClose={() => ShowPaymentView(false)}
                >
                    <PaymentProcess paymentMethod={paymentMethod} Proj={project} Camp={campaign} Pack={pack} Cus={donorInfo} Donation={donation}/>
                </AuiModal>
            </>
        </>
    )
}

export default Donation
