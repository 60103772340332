/**
 *	@copyright	KARD.
 */

/**
 */
export default ( state = {
    profInfo: {},
}, action ) =>
{
    switch( action.type )
    {
        case 'prof:info':
            return {...state, profInfo: action.payload}

        default:
            return state
    }   // switch ...
}   // export
