import React from "react";
import "../home/style.css";
// reactstrap components
import { navigate } from "@reach/router";
import { Container, Row, Col } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function Covid() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "50vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" +
                require("assets/img/works/cov/covid.jpg").default +
                ")",
            }}
          ></div>
          <div className="content-center">
            <Container>
              <h3 href="#" className="text-white">
                Special Interventions
              </h3>
              {/* <a
                className="btn btn-lg text-white pt-3 cmpBtn"
                color="info"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  navigate("/donation/ext/1642076995/1642077071221");
                }}
              >
                Donate
              </a> */}
            </Container>
          </div>
        </div>

        <div className="section">
          <Container>
            <Row>
              <Col sm="9" className="mx-auto">
                <p>
                  The COVID-19 pandemic resulted in an unprecedented global
                  social and economic disruption resulting in one of the largest
                  global recessions which left governments, business leaders,
                  health and scientific communities with the task of curbing its
                  spread and impact.
                </p>
                <p>
                  In line with emerging protocols leading the global efforts to
                  contain the pandemic, African governments, including Nigeria
                  adopted a combination of measures- closing borders, grounding
                  flights, shutting down educational institutions, enforcing
                  physical separation, stay-at-home orders, and closure of
                  non-essential businesses and industries. This in turn
                  disrupted lives and livelihoods most of which are earned on a
                  daily basis in global South countries like Nigeria.
                </p>

                <p>
                  In April 2020, 5000 bags of rice and 5,000 packs of 500ml hand
                  sanitizer were successfully distributed to various communities
                  in Akuku-Toru, Asari-Toru, Degema, Obio-Akpor and Port
                  Harcourt Local Government Areas in Rivers State. Alongside
                  this distribution, we worked to educate the community about
                  the virus, address common misconceptions surrounding the virus
                  and encourage protective measures to benefit communities and
                  institutions.
                </p>

                <img
                  alt="..."
                  className="rounded img-fluid center-img"
                  src={require("assets/img/works/cov/covid.jpg").default}
                ></img>
                <p className="mt-5">
                  In May 2020, 2000 bags of rice were distributed to communities
                  Kano and Kogi states through the collaborative effort with the
                  Ahmadu Yakubu Foundation.
                </p>
                <p>
                  Selected organizations and groups were also supported, and
                  benefited from the intervention offered by the Foundation.
                  They included the following:
                </p>
                <ul className="list-group pl-3" style={{ fontSize: 18 }}>
                  <li className="font-weight-bold mb-2">
                    Those on the frontlines of the containment efforts-
                    (Security Agencies – the Army and Police),
                  </li>
                  <li className="font-weight-bold mb-2">
                    The Red Cross, Journalists and public workers who kept
                    public areas clean even during the lockdown, and
                  </li>
                  <li className="font-weight-bold mb-2">
                    Widows, and families of volunteers, and the prison
                    communities.
                  </li>
                </ul>
                {/* <a
                  className="btn btn-lg text-white pt-3 cmpBtn"
                  color="info"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    navigate("/donation/ext/1642076995/1642077071221");
                  }}
                >
                  Donate
                </a> */}
              </Col>
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default Covid;
