import React from "react";
import "../home/style.css";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function Video() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "30vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/hero.jpg").default + ")",
            }}
          ></div>
          <div className="content-center">
            <Container>
              <h2 href="#" className="text-white title p-0">
                Videos
              </h2>
            </Container>
          </div>
        </div>
        <div className="section section-image-gallery">
          <Container>
            <h3 className="title mb-5">Programmes </h3>
            <Row>
              <Col sm="12" md="6" className="mb-4">
                <div className="embed-responsive embed-responsive-16by9 mb-2">
                  <iframe
                    className="embed-responsive-item"
                    src="https://www.youtube.com/embed/HI99T2ZLdsg"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <div className="progMeta">
                  <div className="meta">
                    <span>Name:</span> Free Medical Missions
                  </div>
                  {/* <div className="meta">
                    <span>Date: </span>
                    1-2-2022
                  </div>
                  <div className="meta">
                    <span>Venue: </span> UK
                  </div> */}
                </div>
              </Col>
              <Col sm="12" md="6" className="mb-4">
                <div className="embed-responsive embed-responsive-16by9 mb-2">
                  <iframe
                    className="embed-responsive-item"
                    src="https://www.youtube.com/embed/AZ1gHk-CB1Q"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <div className="progMeta">
                  <div className="meta">
                    <span>Name:</span> Education & Scholarships
                  </div>
                  {/* <div className="meta">
                    <span>Date: </span>
                    1-2-2022
                  </div>
                  <div className="meta">
                    <span>Venue: </span> UK
                  </div> */}
                </div>
              </Col>
              <Col sm="12" md="6" className="mb-4">
                <div className="embed-responsive embed-responsive-16by9 mb-2">
                  <iframe
                    className="embed-responsive-item"
                    src="https://www.youtube.com/embed/l6YQ_TpaTWc"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <div className="progMeta">
                  <div className="meta">
                    <span>Name:</span> Access to Potable Water and Sanitation
                  </div>
                  {/* <div className="meta">
                    <span>Date: </span>
                    1-2-2022
                  </div>
                  <div className="meta">
                    <span>Venue: </span> UK
                  </div> */}
                </div>
              </Col>
              <Col sm="12" md="6" className="mb-4">
                <div className="embed-responsive embed-responsive-16by9 mb-2">
                  <iframe
                    className="embed-responsive-item"
                    src="https://www.youtube.com/embed/euyE6Jr7mkM"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <div className="progMeta">
                  <div className="meta">
                    <span>Name:</span> Care For Life
                  </div>
                  {/* <div className="meta">
                    <span>Date: </span>
                    1-2-2022
                  </div>
                  <div className="meta">
                    <span>Venue: </span> UK
                  </div> */}
                </div>
              </Col>
              <Col sm="12" md="6" className="mb-4">
                <div className="embed-responsive embed-responsive-16by9 mb-2">
                  <iframe
                    className="embed-responsive-item"
                    src="https://www.youtube.com/embed/vyyCXVV0eTk"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <div className="progMeta">
                  <div className="meta">
                    <span>Name:</span> Micro-credit and Entrepreneurship
                  </div>
                  {/* <div className="meta">
                    <span>Date: </span>
                    1-2-2022
                  </div>
                  <div className="meta">
                    <span>Venue: </span> UK
                  </div> */}
                </div>
              </Col>
            </Row>
            <h3 className="mb-5 title">Impacting Lives</h3>
            <Row>
              <Col sm="12" md="6">
                <div className="embed-responsive embed-responsive-16by9 mb-2">
                  <iframe
                    className="embed-responsive-item"
                    src="https://www.youtube.com/embed/T4HFoMOT6iM"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <div className="progMeta">
                  <div className="meta">
                    <span>Name:</span> Live
                  </div>
                  {/* <div className="meta">
                    <span>Date: </span>
                    1-2-2022
                  </div>
                  <div className="meta">
                    <span>Venue: </span> UK
                  </div> */}
                </div>
              </Col>
              <Col sm="12" md="6">
                <div className="embed-responsive embed-responsive-16by9 mb-2">
                  <iframe
                    className="embed-responsive-item"
                    src="https://www.youtube.com/embed/0mwTja0qLs8"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <div className="progMeta">
                  <div className="meta">
                    <span>Name:</span> Covid-19 Intervention
                  </div>
                  {/* <div className="meta">
                    <span>Date: </span>
                    1-2-2022
                  </div>
                  <div className="meta">
                    <span>Venue: </span> UK
                  </div> */}
                </div>
              </Col>
            </Row>
            <h3 className="mb-5 title">
              O.B. Lulu Briggs Special Interventions
            </h3>
            <Row>
              <Col sm="12" md="6">
                <div className="embed-responsive embed-responsive-16by9 mb-2">
                  <iframe
                    className="embed-responsive-item"
                    src="https://www.youtube.com/embed/pge7xefawhU"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <div className="progMeta">
                  <div className="meta">
                    <span>Name:</span> Christmas Outreach 2020
                  </div>
                  {/* <div className="meta">
                    <span>Date: </span>
                    1-2-2022
                  </div>
                  <div className="meta">
                    <span>Venue: </span> UK
                  </div> */}
                </div>
              </Col>
              <Col sm="12" md="6">
                <div className="embed-responsive embed-responsive-16by9 mb-2">
                  <iframe
                    className="embed-responsive-item"
                    src="https://www.youtube.com/embed/wH2e79mfNHI"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <div className="progMeta">
                  <div className="meta">
                    <span>Name:</span> Christmas Outreach 2020
                  </div>
                  {/* <div className="meta">
                    <span>Date: </span>
                    1-2-2022
                  </div>
                  <div className="meta">
                    <span>Venue: </span> UK
                  </div> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default Video;
