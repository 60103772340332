import Switch from "react-bootstrap-switch";
import {Col} from "reactstrap";
import React, {useState} from "react";

const Consent = ({GiftAid, Consent, OnUpdate}) => {
    const [giftAid, SetGiftAid] = useState(false);
    const [consent, SetConsent] = useState(false);
    const updateConsent = (type) => {
      if (type === 'gift'){
          SetGiftAid(!giftAid);
      }else {
          SetConsent(!consent)
      }
      OnUpdate(type)
    }
  return(
      <Col sm="12" className="consent">
          <h3 className="donateTitle">Consent</h3>

          <div className="row">
              <div className="col-sm-6">
                  <div className="border rounded p-3">
                      <h5>
                          Gift Aid
                          <span className="pull-right" style={{ fontSize: 12 }}>
                          OFF &nbsp;
                              <Switch
                                  value={giftAid}
                                  onChange={() => updateConsent("gift")}
                              />
                              &nbsp; ON
                        </span>
                      </h5>
                      <p className="donate-description">
                          I am a UK taxpayer and i wish ({"this charity"}) to
                          reclaim tax back on all donations i have made with the
                          last 6 years and all donations that i make hereafter.
                      </p>
                  </div>
              </div>
              <div className="col-sm-6 ">
                  <div className="border rounded p-3">
                      <h5>
                          Consent (Project)
                          <span className="pull-right" style={{ fontSize: 12 }}>
                          OFF &nbsp;
                              <Switch
                                  value={consent}
                                  onChange={() => updateConsent("consent")}
                              />
                              &nbsp; ON
                        </span>
                      </h5>
                      <p className="donate-description">
                          I consent to receive news and updates from this charity
                          and about this campaign.
                      </p>
                  </div>
              </div>
          </div>
      </Col>
  )
}
export default Consent
