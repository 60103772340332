import {Col} from "reactstrap";

const Message = ({message,UpdateMessage}) => {
  return(
      <Col sm="12" className="mt-4">
          <div className="">
              <h3 className="donateTitle">Message</h3>
              <textarea
                  className="form-control p-3"
                  id="textareaChars"
                  type="text"
                  maxLength="280"
                  rows="100"
                  cols="50"
                  placeholder="Max 280 Charecter"
                  value={message}
                  onChange={(e) => {
                      UpdateMessage(e.target.value);
                  }}
              />
              <span id="chars">{280 - message.length}</span> characters
              remaining
          </div>
      </Col>
  )
}
export default Message
