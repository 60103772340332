import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";

import { navigate } from "@reach/router";

// core components

function HeroSection() {
  let pageHeader = React.createRef();

  return (
    <>
      <div className="page-header" style={{ background: "none" }}>
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/home-hero-img.jpg").default + ")",
          }}
          ref={pageHeader}
        ></div>
        <div className="content-center-hero">
          <Container>
            <h2 className="hero-title title text-white text-left">
              <div className="serComm">
                Implementing
                <br />
                sustainable interventions
                <br />
                that enhance the quality of Life or underserved persons across
                <br />
              </div>
              <span> Nigeria</span>
              {/* <p className="hero-paragraph my-2">
                Celebrating two decades of improving the health and conditions
                <div className="lineBrk">of vulnerable communities</div>
                  Implementing sustainable interventions <br /> that enhance the
                quality of Life
                <br /> for underserved persons across Nigeria
              </p> */}
            </h2>
            <div className="heroColor">
              <p className="supportProject text-white my-4">
                <span
                  style={{ fontWeight: "bolder", cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    navigate("/work");
                  }}
                >
                  Learn more about our work
                </span>
              </p>
              {/* <a
                className="btn-create-campaign btn-outline-info btn-lg text-white mb-2 cmpBtn"
                color="info"
                style={{ cursor: "pointer" }}
                // href="/crowd/campaign/create/basics"
                // href="/crowd/campaign/create"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  navigate("/crowd/campaign/create");
                }}
              >
                Create a Campaign
              </a> */}
              {/* <a
                className="btn-lg text-white mb-2 cmpBtn cmpBtnlong"
                color="info"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  navigate("/donation/ext");
                }}
              >
                Donate
              </a> */}
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
