import {Col} from "reactstrap";
import React, {useEffect, useRef, useState} from "react";
import {FormButton} from "./common_ui";
import {GetStripePK} from "../../rdx/actions";
import {useDispatch} from "react-redux";


const PaymentMethod = ({onChange, selected}) => {
    const mounted = useRef(true);
    const dispatch = useDispatch();
    const [cardStatus, SetCardStatus] = useState(false)
    const [stripePK, SetStripePK] = useState("")


    const Init = async () => {
        try {
            const pk = await dispatch(GetStripePK('ext'));
            console.log(pk)
            if (!mounted.current)
                return null
            if (pk)
            {
                SetCardStatus(true);
                SetStripePK(pk)
            }
            console.log("pk",pk);
        }catch (e) {
            if (mounted.current)
                SetCardStatus(false);
        }
    }

    useEffect(() => {
        Init().then().catch();
        return () => {
            if (mounted.current){
                mounted.current = false
            }
        };
    }, []);


    return (
        <Col sm="12">
            <div className="row">
                <div className="col-sm-12">
                    <h3 className="donateTitle">Payment Method</h3>
                </div>
                <div className="row col-sm-12">
                    <div className={"col-sm-3"}>
                        <FormButton selected={selected?.m === "card"} text={"Card"} onPress={()=>onChange({ m: "card", s: "not-paid",l:"Card", pk:stripePK })} disabled={!cardStatus}/>
                    </div>
                    <div className={"col-sm-3"}>
                        <FormButton selected={selected?.m === "paypal"} text={"PayPal"} onPress={()=>onChange({ m: "paypal", s: "not-paid",l:"Paypal" })}/>
                    </div>
                    <div className={"col-sm-3"}>
                        <FormButton selected={selected?.m === "bank"} text={"Bank"} onPress={()=>onChange({ m: "bank", s: "not-paid",l:"Bank" })}/>
                    </div>
                    <div className={"col-sm-3"}>
                        <FormButton selected={selected?.m === "cheque"} text={"Cheque"} onPress={()=>onChange({ m: "cheque", s: "not-paid",l:"Cheque" })}/>
                    </div>
                </div>
            </div>
        </Col>
    )
}
export default PaymentMethod;

