import React from "react";
import "../home/style.css";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  UncontrolledCollapse,
  Button,
  CardBody,
} from "reactstrap";
import Switch from "react-bootstrap-switch";
// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function GrandBenefactor() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        {/* <div className="page-header" style={{ minHeight: "60vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/hero.jpg").default + ")",
            }}
          ></div>
          <div className="content-center">
            <Container>
              <h2 href="#" className="text-white">
                Our Grand Benefactor
              </h2>
            </Container>
          </div>
        </div> */}
        <div className="section section-campaign">
          <Container>
            {/* <Row>
              <Col className="ml-auto mr-auto text-center" md="6" sm="6">
                <img
                  style={{ maxWidth: "250px" }}
                  alt="..."
                  className="rounded img-fluid"
                  src={require("assets/img/lulu-briggs.png").default}
                ></img>
              </Col>
              <Col className="ml-auto mr-auto" md="6" sm="6">
                <h2 className="title">
                  HIGH CHIEF (DR.)
                  <br /> O. B. LULU-BRIGGS, OON, DCF, DSRSS
                  <br /> (1930 – 2018)
                </h2>
                <h4 className="category">
                  STATESMAN, BUSINESS MAGNATE, PHILANTHROPIST, INDUSTRIALIST
                </h4>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <h3
                  className="text-info font-italic my-5"
                  style={{ lineHeight: "40px" }}
                >
                  “ The true worth of a man is the way he treats people who are
                  absolutely in need. If you care about people in need, you are
                  actually making a contribution to the development of the
                  nation.”
                </h3>
                <p>
                  High Chief Lulu-Briggs was born on May 22, 1930 in Abonnema,
                  Akuku-Toru Local Government Area of Rivers State, Nigeria into
                  two illustrious Kalabari families. His father, the late Benson
                  Lulu-Briggs of the Oruwari Briggs War-Canoe House of Abonnema,
                  was the grandson of the legendary Chief Iniikeiroari Young
                  Briggs(Oruwari II) , the legendary merchant prince, founder,
                  and sole defender of Abonnema. His mother, the late Rachael
                  Kioba Harrison Kio Lawson of the Atonaye Pakaa (Pepple) of
                  King Amachree Royal House, was the first daughter of the late
                  Chief Harrison Kio Lawson. Her paternal great grandmother was
                  the daughter of Agama, the founder of Krakrama.
                </p>
                <p>
                  High Chief (Dr.) Olu Benson Lulu-Briggs, OON, DCF, DSSRS,
                  Iniikeiroari V of Kalabari Kingdom; Oruwari VI, Paramount Head
                  of Oruwari Briggs War- Canoe House of Abonnema; Founder and
                  Chairman, Moni Pulo Nigeria; revered Nigerian elder statesman,
                  politician, trade unionist, businessman and philanthropist –
                  and our grand benefactor – passed into glory in Accra, Ghana
                  on December 27, 2018. Our Foundation was established in his
                  name by his wife, Dr. (Mrs.) Seinye O. B. Lulu-Briggs in 2001
                  to honor, celebrate, structure, and institutionalize his
                  prolific charitable and philanthropic giving.
                  <br />
                  High Chief O.B. Lulu-Briggs lost his father at a very early
                  age and his only brother, Onyema Tubonimi Lulu-Briggs, died
                  just a few days later. As was the tradition at the time, the
                  elder male members of the family inherited his father’s
                  wealth, leaving him and his mother to struggle financially.
                </p>
                <p>
                  He came from a long line of rich merchants and traders and had
                  grown up being regaled with stories about their adventures and
                  immense successes in international transatlantic trade with
                  western merchants, as equals. Naturally, he went into business
                  eventually, but life was not initially easy.
                </p>
                <p>
                  At an early age, under the care of his paternal grandmother,
                  he became acquainted with Jesus Christ. He would remain a
                  committed Christian throughout his life. Indeed, he would
                  often talk about how it was his unwavering belief in Christ’s
                  promise that stood as the constant in his life. These
                  experiences would later result in his desire to help as many
                  people as he could when he became able to do so. Despite the
                  loss of fatherly support, even as a young businessman, he
                  educated himself and actively sought formal employment. His
                  tenacity, hard work, fearless attitude, and good nature paid
                  off as he rose above his tribulations to become one of the
                  wealthiest Nigerians with a career that took him from a clerk,
                  civil servant, trade unionist and politician to a businessman.
                </p>
                <p>
                  He gained national recognition for his progressive role as the
                  National Deputy Chairman of the now- defunct National Party of
                  Nigeria, the dominant political party in Nigeria from 1979 to
                  1983. In this position, he was able to contribute to
                  development in Nigeria. His was a respected voice, which he
                  used to push for recognition of issues such as services for
                  the country’s large populace and environmental challenges and
                  inadequate funding for the Niger Delta region that provides
                  most of Nigeria’s revenue.
                </p>
                <p>
                  He remained a supporter of progressive politics in Nigeria
                  through involvement in the National Democratic Coalition
                  (NADECO), the pro-democracy group that fought for the return
                  of democracy to Nigeria. He also played key roles in the
                  formation of the Alliance for Democracy (AD) alongside
                  progressive politicians such as Bola Ige, Abraham Adesanya,
                  and Emeka Ezeife in the fourth republic.
                </p>
                <p>
                  He was also widely applauded for his business acumen in his
                  role as Chairman and Chief Executive of Moni Pulo Limited, a
                  pioneering indigenous oil and gas company in the upstream
                  sector which he founded in 1992. Today, it is greatly admired
                  across the sector.
                </p>
                <img
                  alt="..."
                  className="rounded img-fluid img-raised"
                  src={require("assets/img/grand-benefactor.png").default}
                ></img>
                <p className="mt-5">
                  High Chief O. B. Lulu-Briggs’ boundless generosity was
                  legendary in his lifetime, and many people argue that he was
                  the greatest Nigerian philanthropist of his generation. The
                  Foundation’s work gave him great joy because, through it, he
                  was able to touch and change many lives, build institutions,
                  and promote humanity, and achieve the promise he made himself
                  as a struggling adolescent.
                </p>
                <p className="mt-5">
                  He is survived by his beloved wife, Dr (Mrs) Seinye O.B.
                  Lulu-Briggs, daughter of Mr. Ernest Francis Agbani Briggs and
                  Mrs Darling E.F. Briggs (nee William-West) and his children:
                  Senibo Lulu-Briggs, Dumo Lulu-Briggs, Sofiri Lulu-Briggs,
                  Solate Ovundah-Akarolo, Aima Ojehomon, Rachael Lulu-Briggs,
                  Dateim Lulu-Briggs, Tamuno-Iyowuna Benson Lulu-Briggs, several
                  grandchildren and great- grandchildren.
                </p>
              </Col>
            </Row> */}
            <Row>
              <Col lg="12" sm="6">
                <h3>Customize your preference</h3>
                <p>
                  We use several types of cookies to run our website, for
                  analytics and to personalise the content you see, like showing
                  you relevant ads and remembering you every time you visit. If
                  you disable certain cookies, you may not be able to use some
                  of the functions of our website or enjoy a personalised
                  experience. You can choose the type of cookies we use here.
                </p>
                <Card className="p-5">
                  <div>
                    <Switch
                      offColor=""
                      offText=""
                      onColor=""
                      onText=""
                    ></Switch>
                    <span className="font-weight-bold h5">
                      Partner Marketing Cookies
                    </span>

                    <p>
                      These cookies can be used to display third party’s (our
                      partners) advertisements that may be relevant to you and
                      measure the effectiveness of marketing activities that our
                      partners run in cooperation with us.
                      <br />
                      <a
                        color="primary"
                        href="#collapseExampleEc"
                        id="linkTogglerMc"
                        style={{
                          fontSize: 13,
                          borderBottom: "1px solid #f96332",
                        }}
                      >
                        Show more
                      </a>
                      <UncontrolledCollapse toggler="#linkTogglerMc">
                        These cookies are set by third parties (our partners) to
                        track your interests, including information about the
                        products and services you are interested in, or
                        purchase, on our Platforms. This information can be used
                        to make the third parties’ advertising displayed on
                        other Platforms more relevant to you and measure the
                        effectiveness of, and optimize, marketing activities
                        that our partners run in cooperation with us. Please
                        note that our partners are also data controllers of the
                        personal information collected and processed via these
                        cookies and they will use this information for their own
                        purposes. These third parties, however, use non-directly
                        identifiable information (which does not include, for
                        example, your name, address, email address or telephone
                        number). We encourage you to read the privacy statements
                        and relevant notices of these third parties.
                      </UncontrolledCollapse>
                    </p>
                  </div>
                  <div>
                    <Switch
                      offColor=""
                      offText=""
                      onColor=""
                      onText=""
                    ></Switch>
                    <span className="font-weight-bold h5">
                      Essential Cookies
                    </span>

                    <p>
                      These cookies are required to perform basic website
                      functionality.
                      <br />
                      <a
                        color="primary"
                        href="#collapseExampleEc"
                        id="linkTogglerEc"
                        style={{
                          fontSize: 13,
                          borderBottom: "1px solid #f96332",
                        }}
                      >
                        Show more
                      </a>
                      <UncontrolledCollapse toggler="#linkTogglerEc">
                        These cookies are essential for the operation of our
                        Platforms. They include, for example, cookies which
                        enable you to log into secure areas of our websites;
                        progress or complete a booking; or make use of our other
                        online services. These cookies also help us understand
                        how users navigate and interact with our website, which
                        means we can continue to improve the overall experience
                        without identifying or targeting any individual user.
                      </UncontrolledCollapse>
                    </p>
                  </div>
                  <div>
                    <Switch
                      offColor=""
                      offText=""
                      onColor=""
                      onText=""
                    ></Switch>
                    <span className="font-weight-bold h5">
                      Functional Cookies
                    </span>

                    <p>
                      These cookies allow us to personalise content for you and
                      improve your experience.
                      <br />
                      <a
                        color="primary"
                        href="#collapseFc"
                        id="linkTogglerFc"
                        style={{
                          fontSize: 13,
                          borderBottom: "1px solid #f96332",
                        }}
                      >
                        Show more
                      </a>
                      <UncontrolledCollapse toggler="#linkTogglerFc">
                        These cookies are used to recognise a user when they
                        return to our Platforms. This enables us to personalise
                        content for you, greet you by name, provide your
                        Skywards details if you are registered, remember your
                        preferences (for example, your choice of language or
                        region) and provide assistance where necessary.
                      </UncontrolledCollapse>
                    </p>
                  </div>
                  <div>
                    <Switch
                      offColor=""
                      offText=""
                      onColor=""
                      onText=""
                    ></Switch>
                    <span className="font-weight-bold h5">
                      Marketing Cookies
                    </span>

                    <p>
                      These cookies are used to display ads that may be relevant
                      to you.
                      <br />
                      <a
                        color="primary"
                        href="#collapseExampleMc"
                        id="linkTogglerMc"
                        style={{
                          fontSize: 13,
                          borderBottom: "1px solid #f96332",
                        }}
                      >
                        Show more
                      </a>
                      <UncontrolledCollapse toggler="#linkTogglerMc">
                        These cookies record your visit to our websites, the
                        pages you have visited and actions you have taken. We
                        will use this information to make our advertising
                        displayed on other platforms (by third party advertising
                        providers) more relevant to you. We may share this
                        information with third parties for this purpose, for
                        example, we may serve you a relevant advertisement based
                        on your browsing history with our platforms. We also use
                        third party cookies so we can personalise our
                        advertisements to you and to help us analyse our
                        customers' behaviour. Our third party advertising
                        providers set anonymous cookies on other websites that
                        we advertise on. If you receive one of those cookies, we
                        may then use it to identify you as having visited that
                        site if you later visit our Platforms. We then
                        personalise our advertisements to you based on this
                        information.
                      </UncontrolledCollapse>
                    </p>
                  </div>
                  <p>
                    You can change your preference at any time. For more
                    infromation please see our <a href="#">cookie policy</a>.
                  </p>
                </Card>
              </Col>
              <Col>
                <button className="btn btn-primary">Back</button>
                <button className="btn btn-secondary">Save & Continue</button>
              </Col>
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default GrandBenefactor;
