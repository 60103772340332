/**
 *	@copyright	KARD.
 */
import * as Cfg from "./cfg";
import * as AWS from '../../api/AWS'

/**
 */
export const NewsList = ( p ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new AWS.Lambda( {} )

            const aws_core = new AWS.Core( {} )
            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()
            const lambda_name = d( Cfg.Lambda('unauth') )

            const p_list = {
                actType: 'news',
                act: 'news:list',
                c_id:   gs().__cfg.oId,

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    cp_n: p?.cp_n,
                    dt_end: p?.dt_end,
                    dt_st: p?.dt_st,
                    last_key: p?.last_key,
                    pr_n: p?.pr_n,
                },
            }

            // console.log( 'actions/News: NewsList: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, lambda_name )

            // console.log( 'actions/News: NewsList: resp_list: ', resp_list )

            //
            return {...resp_list}
        }
        catch( err )
        {
            console.warn( 'actions/News: NewsList: err: ', err )

            return Promise.reject( err )
        }
    }
}   // NewsList
/**
 */
export const FeaturedNewsList = ( p ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            const aws_lambda = new AWS.Lambda( {} )

            const aws_core = new AWS.Core( {} )
            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()
            const lambda_name = d( Cfg.Lambda('unauth') )

            const p_list = {
                actType: 'news',
                act: 'news:featured-list',
                c_id:   gs().__cfg.oId,

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    last_key: p?.last_key,
                },
            }

            // console.log( 'actions/News: NewsList: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, lambda_name )

            console.log( 'actions/News: NewsList: resp_list: ', resp_list )

            //
            return {...resp_list}
        }
        catch( err )
        {
            console.warn( 'actions/News: NewsList: err: ', err )

            return Promise.reject( err )
        }
    }
}   // NewsList
