import {Col} from "reactstrap";
import Autocomplete from "react-google-autocomplete";
import React, {useEffect, useReducer} from "react";
const INITIAL_STATE = {
    name:{f:"",l:"",t:""},
    phone:{n:"",cn:""},
    address:{
        l1:"",
        l2:"",
        c: "",
        p: "",
        cn: "",
        pc: "",
    },
    email:"",
}
const COUNTRY_CODE = [
    {val:"44", name:"UK (+44)"},
    {val:"880", name:"BD (+880)"},
]
const TITLE = [
    {val:"mr", name:"MR"},
    {val:"ms", name:"MS"},
    {val:"mrs", name:"MRS"},
]
const init = (is) =>{
    return is;
}
const reducer = (state, action) =>{
    switch (action.type) {
        case "phone-cn": return {
            ...state,
            phone:{
                ...state.phone,
                cn:action.payload
            }
        };
        case "phone-n": return {
            ...state,
            phone:{
                ...state.phone,
                n:action.payload
            }
        };
        case "name-t": return {
            ...state,
            name:{
                ...state.name,
                t:action.payload
            }
        };
        case "name-f": return {
            ...state,
            name:{
                ...state.name,
                f:action.payload
            }
        };
        case "name-l": return {
            ...state,
            name:{
                ...state.name,
                l:action.payload
            }
        };
        case "email": return {
            ...state,
            email:action.payload
        };
        case "address": return {
            ...state,
            address:{...state.address,...action.payload}
        };

        default: return state;
    }
}

const DonorInfo = ({info,OnChange}) => {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE, init);

    useEffect(()=>{
        OnChange(state);
    },[state])
    // console.log(state)
    return(
        <Col sm="12">
            <div className="">
                <h3 className="donateTitle">Phone Number</h3>
                <div className="row">
                    <div className="col-sm-4">
                        <CustomSelect value={state.phone.cn} required={true} label={"Country"} options={COUNTRY_CODE} OnChange={val=>dispatch({type:"phone-cn",payload:val})}/>
                    </div>
                    <div className="col-sm-8">
                        <CustomInput
                            label={"Number"}
                            type={"number"}
                            placeholder={"79 0000 0000"}
                            required={true}
                            value={state.phone.n}
                            OnChange={val=>dispatch({type:"phone-n", payload:val})}
                        />
                    </div>
                </div>
                <h3 className="donateTitle">Name</h3>
                <div className="row">
                    <div className="col-sm-4">
                        <CustomSelect
                            value={state.name.t}
                            required={false}
                            label={"Title"}
                            options={TITLE}
                            OnChange={val=>dispatch({type:"name-t",payload:val})}/>
                    </div>
                    <div className="col-sm-4">
                        <CustomInput
                            label={"First"}
                            type={"text"}
                            required={true}
                            value={state.name.f}
                            OnChange={val=>dispatch({type:"name-f", payload:val})}
                        />
                    </div>
                    <div className="col-sm-4">
                        <CustomInput
                            label={"Last"}
                            type={"text"}
                            required={true}
                            value={state.name.l}
                            OnChange={val=>dispatch({type:"name-l", payload:val})}
                        />
                    </div>
                </div>
                <h3 className="donateTitle">Email</h3>
                <div className="row">
                    <div className="col-sm-12">
                        <CustomInput
                            label={"E-mail"}
                            type={"text"}
                            placeholder={"example@mail.com"}
                            value={state.email}
                            OnChange={val=>dispatch({type:"email", payload:val})}
                        />
                    </div>
                </div>
                <h3 className="donateTitle">Address</h3>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label>Address Lookup</label>
                            <Autocomplete
                                apiKey={"AIzaSyDQaXKe4roziIjDQAFFC2wdJQrHM9K1o8M"}
                                onPlaceSelected={({address_components}) => {
                                    // console.log(place);
                                    const address = address_components.reduce((acc,{long_name,short_name, types})=>{
                                        // console.log(acc);
                                        switch (true) {
                                            case types.includes("postal_code"):
                                                acc["pc"] = long_name;
                                                return acc;
                                            case types.includes("route"):
                                                acc["l2"] = long_name;
                                                return acc;
                                            case types.includes("postal_town"):
                                                acc["c"] = long_name;
                                                return acc;
                                            case types.includes("administrative_area_level_2"):
                                                acc["p"] = long_name;
                                                return acc;
                                            case types.includes("country"):
                                                acc["cn"] = long_name;
                                                return acc;
                                            default: return acc;
                                        }
                                    },{});
                                    dispatch({type:"address", payload:address});
                                }}
                                className="form-control"
                                placeholder="Search"
                                options={{
                                    types: ["(regions)"],
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <CustomInput
                            label={"Address Line 1"}
                            type={"text"}
                            placeholder={"Address Line 1"}
                            required={true}
                            value={state.address.l1}
                            OnChange={val=>dispatch({type:"address", payload:{l1:val}})}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <CustomInput
                            label={"Address Line 2"}
                            type={"text"}
                            placeholder={"Address Line 2"}
                            value={state.address.l2}
                            OnChange={val=>dispatch({type:"address", payload:{l2:val}})}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <CustomInput
                            label={"City"}
                            type={"text"}
                            placeholder={"City"}
                            required={true}
                            value={state.address.c}
                            OnChange={val=>dispatch({type:"address", payload:{c:val}})}
                        />
                    </div>
                    <div className="col-sm-6">
                        <CustomInput
                            label={"Country / Province"}
                            type={"text"}
                            placeholder={"Country / Province"}
                            value={state.address.p}
                            OnChange={val=>dispatch({type:"address", payload:{p:val}})}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <CustomInput
                            label={"Postcode"}
                            type={"text"}
                            placeholder={"Postcode"}
                            required={true}
                            value={state.address.pc}
                            OnChange={val=>dispatch({type:"address", payload:{pc:val}})}
                        />
                    </div>
                    <div className="col-sm-6">
                        <CustomInput
                            label={"Country"}
                            type={"text"}
                            placeholder={"Country"}
                            value={state.address.cn}
                            OnChange={val=>dispatch({type:"address", payload:{cn:val}})}
                        />
                    </div>
                </div>
            </div>
        </Col>
    )
}
export default DonorInfo


const CustomSelect = ({value,options,label,required,OnChange}) => {

    return(
        <div className="form-group">
            <label>
                {label}
                { required && <span className="text-danger"> *</span>}
            </label>
            <select
                className="form-control"
                required={required}
                value={value}
                onChange={(e) =>OnChange(e.target.value)}
            >
                <option value="">Select</option>
                {
                    options.map(data=><option value={data.val}>{data.name}</option>)
                }
            </select>
        </div>
    )
}

const CustomInput = ({value,label,required,OnChange,placeholder, type}) => {

    return(
        <>
            <div className="form-group">
                <label>
                    {label}
                    {required && <span className="text-danger"> *</span>}
                </label>
                <input
                    className="form-control"
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        OnChange(e.target.value)
                    }}
                    required={required}
                />
            </div>
        </>

    )
}
