import React from "react";
import logo from "assets/img/ob-logo.png";
import { Link } from "@reach/router";

// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

import { connect } from "react-redux";
import * as actions from "rdx/actions";

function TransparentNavbar(props) {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  //
  React.useEffect(() => {
    // console.log("TransparentNavbar: props: ", props);

    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  }, []);

  // React.useEffect(() => {
  //   console.log("TransparentNavbar: props: ", props);
  // }, [props.__auth.status]);

  //
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} color="info" expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand href="/home" id="navbar-brand">
              <img src={logo} alt="Logo" style={{ maxWidth: "220px" }} />
            </NavbarBrand>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink href="/home">Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/crowd">Crowd</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/work">Our Works</NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink href="/about/us">About Us</NavLink>
              </NavItem> */}
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  href="/about/us"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <p>
                    <span style={{ color: "#fff" }}>About</span>
                  </p>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem to="/about/us" tag={Link}>
                    <i className="now-ui-icons arrows-1_minimal-right mr-1"></i>
                    About Us
                  </DropdownItem>
                  <DropdownItem to="/about/our-team" tag={Link}>
                    <i className="now-ui-icons arrows-1_minimal-right mr-1"></i>
                    Our Team
                  </DropdownItem>
                  <DropdownItem href="/about/grand-benefactor">
                    <i className="now-ui-icons arrows-1_minimal-right mr-1"></i>
                    Our Grand Benefactor
                  </DropdownItem>
                  <DropdownItem href="/about/contact">
                    <i className="now-ui-icons arrows-1_minimal-right mr-1"></i>
                    Contact
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                {/* <NavLink href="/auth/login"><i className="fa fa-user" /> Sign In</NavLink> */}
                {"loggedin" === props.__auth.status ? (
                  <NavLink id="twitter-tooltip" tag={Link} to="/profile">
                    <i className="fa fa-user mr-1"> </i>
                    {" Profile"}
                  </NavLink>
                ) : (
                  <NavLink id="twitter-tooltip" tag={Link} to="/auth/login">
                    <i className="fa fa-user mr-1"> </i>
                    {" Sign In"}
                  </NavLink>
                )}
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

// export default TransparentNavbar;
/**
 */
const mapStateToProps = (state) => {
  return state;
}; //

/**
 */
export default connect(mapStateToProps, actions)(TransparentNavbar);
