import React, { useEffect, useRef, useState } from "react";
import "../home/style.css";
// import Autocomplete from "react-google-autocomplete";

// import Switch from "react-bootstrap-switch";

import { navigate, Link } from "@reach/router";

// reactstrap components
import {
  Button,
  Alert,
  Container,
  Row,
  Col,
  Form,
  Modal,
  ModalBody,
} from "reactstrap";
// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";

/**
 */
function DonationSuccess(props) {
  /**
   */
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div
          className="donation-success"
          style={{ background: "rgb(0 158 251 / 60%)", height: "100vh" }}
        >
          <Container style={{ padding: "5rem" }}>
            <Col sm="12" className="text-center">
              {/* <h2 className="mt-5" style={{ color: "#fff", paddingTop: 20 }}>
                Donation Success
              </h2> */}
              {/* <img
                style={{ maxWidth: "80%" }}
                alt="..."
                className="rounded img-fluid mt-5"
                src={require("assets/img/jajakallah2.png").default}
              ></img> */}
              <h2
                className="successColor text-white"
                style={{ marginTop: "7rem" }}
              >
                Congratulation
              </h2>
              <p>Your DONATION was successful</p>
            </Col>
            <Col sm="12" className="text-center my-5 mt-5">
              {/* <p>
                "Spend (on charity), O son of Adam, and I shall spend on you"
              </p>
              <h2 className="successColor">~ Al-Quran ~</h2> */}
              <button
                className="btn btn-white btn-lg mt-5"
                style={{ fontSize: 18 }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  navigate("/");
                }}
              >
                Back to Home
              </button>

              <button
                className="btn btn-info btn-lg border mt-5"
                style={{ fontSize: 18 }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  navigate("/crowd/campaign/all");
                }}
              >
                View All Campaigns
              </button>
            </Col>
          </Container>
        </div>
      </div>
    </>
  );
} // DonationSuccess

// End Modal
export default DonationSuccess;
