import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {Time} from "../../api/Utils";
import {CircularProgress} from "@material-ui/core";
import {GetPubProjectList} from "../../rdx/actions";
import ListProj from "./list_proj";
import AuiModal from "../../aui/modal";
import {OptionItem} from "./option_item";


const OptionProject = ({ type, OnSelect, Project }) => {
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const [selectedProject, SelectProject] = useState(null);
    const [isShowingProjectList, ShowProjectList] = useState(false);
    const [isLoading, SetLoading] = useState(false);
    const [projectList, UpdateProjectList] = useState([]);
    const [lastKey, SetLastKey] = useState(null);
    const dtNow = Time.Epoch(Date.now());
    const LoadProject = async () => {
        try {
            SetLoading(true);
            const { Items, LastEvaluatedKey } = await dispatch(GetPubProjectList(lastKey));
            if (!isMounted.current)
                return null
            UpdateProjectList(lastKey ? [...projectList, ...Items] : Items);
            if (LastEvaluatedKey) {
                SetLastKey(LastEvaluatedKey);
            }
            SetLoading(false);

            return { msg: "ok" };
        } catch (e) {
            SetLoading(false);
            console.warn("ProjectList: LoadProject: Error:", e);
            return Promise.reject(e);
        }
    };

    useEffect(() => {
        if (Project) {
            SelectProject(Project);
        }
    }, [Project]);

    useEffect(() => {
        if (isMounted.current)
        {
            LoadProject().then().catch();
        }
        return ()=>{
            isMounted.current = false
        }
    }, []);

    const SelectProjectHandler = (project) => {
        OnSelect(project);
        SelectProject(project);
        ShowProjectList(!project);
    };

    return(
        <>
            <div className="col-sm-4">
                <button
                    className="btn-block btn btn-info"
                    style={{
                        fontSize: 18,
                        fontWeight: 400,
                        margin: 5,
                        padding: 16,
                        backgroundColor: selectedProject ? "#2680c2" : null,
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        if (isLoading) return "Loading";
                        ShowProjectList(true);
                    }}
                >
                    {isLoading ? (
                        <>
                            Loading...
                            <CircularProgress
                                color="primary"
                                size={10}
                                style={{ top: 10, right: 20, position: "absolute" }}
                            />
                        </>
                    ) : (
                        <>{selectedProject?.p?.t || "Select Project"}</>
                    )}
                </button>
                <AuiModal
                    bOpen={isShowingProjectList}
                    OnClose={() => ShowProjectList(false)}
                >
                    <div
                        className="projModal"
                        style={{
                            overflowX: "hidden",
                            overflowY: "scroll",
                        }}
                    >
                        <div className={"rounded mx-3 p-3 my-4"}>
                            <button
                                className="btn-outline-danger btn"
                                style={{
                                    fontWeight: selectedProject ? 900 : null,
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    SelectProjectHandler(null);
                                }}
                            >
                                <i className="fa fa-remove" style={{ fontSize: 18 }}></i> Clear
                                Selection
                            </button>
                        </div>

                        {isLoading ? (
                            <CircularProgress
                                color="primary"
                                size={200}
                                style={{ top: "30%", left: "38%", position: "absolute" }}
                            />
                        ) : (
                            projectList.map((value) => {
                                const isExp = !!(value.te && value.te > 0 && value.te < dtNow);
                                const isDisable = !(!value.s || value.s === "act");
                                const isSelected = value?.tn === selectedProject?.tn;
                                if (type === "only-active" && (isExp || isDisable)) return null;

                                return (
                                    <OptionItem
                                        key={value.tn}
                                        item={value}
                                        isExp={isExp}
                                        isDisable={isDisable}
                                        isSelected={isSelected}
                                        OnSelect={SelectProjectHandler}
                                    />
                                );
                            })
                        )}
                        <div>
                            {lastKey && (
                                <button
                                    className="btn btn-primary ml-3"
                                    style={{
                                        margin: 5,
                                        padding: 5,
                                    }}
                                    onClick={() => LoadProject().catch((err) => {})}
                                >
                                    Show More
                                </button>
                            )}
                        </div>
                    </div>
                </AuiModal>
            </div>
        </>
    )
}

export default OptionProject
