import React from "react";
import "../home/style.css";
// reactstrap components
import { navigate } from "@reach/router";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardLink,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import Sidebar from "./work-sidebar.js";

function CareForLife() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "50vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" +
                require("assets/img/works/professional/1.jpeg").default +
                ")",
            }}
          ></div>
          <div className="content-center">
            <Container>
              <h3 href="#" className="text-white">
                High Chief Dr. O. B. Lulu-Briggs Distinguished Professorial
                Chair
              </h3>
            </Container>
          </div>
        </div>

        <div className="section">
          <Container>
            <Row>
              <Col sm="9" className="mx-auto">
                <h3 className="font-weight-bold">
                  HIGH CHIEF (DR.) O. B. LULU-BRIGGS DISTINGUISHED PROFESSORIAL
                  CHAIR
                </h3>
                <p>
                  On May 22, 2019, we announced a gift of 50 million Naira to
                  endow the High Chief (Dr.) O.B. Lulu-Briggs Distinguished
                  Professorial Chair in Geriatric Care at the Rivers State
                  University.
                </p>
                <p>
                  This auspicious occasion aptly titled A Legacy of
                  Philanthropic Excellence honoured our late grand benefactor’s
                  legendary giving on his first posthumous birthday. We are
                  confident that the outcomes from work under the endowment will
                  contribute to pioneering work needed to secure adequate
                  resources and advocacy that will support and promote healthy
                  aging in Nigeria. Supporting research in this field is
                  incredibly important both to promote education and health.
                </p>
                <h3>Professional Chair</h3>
                <div className="row">
                  <div className="col-6">
                    <img
                      src={
                        require("assets/img/works/professional/1.jpeg").default
                      }
                      style={{ width: 400, height: 300, marginBottom: 20 }}
                      alt="high chief"
                    />
                  </div>
                  <div className="col-6">
                    <img
                      src={
                        require("assets/img/works/professional/5.jpeg").default
                      }
                      style={{ width: 400, height: 300 }}
                      alt="high chief"
                    />
                  </div>
                  <div className="col-6">
                    <img
                      src={
                        require("assets/img/works/professional/3.jpeg").default
                      }
                      style={{ width: 400, height: 300 }}
                      alt="high chief"
                    />
                  </div>
                  <div className="col-6">
                    <img
                      src={
                        require("assets/img/works/professional/4.jpeg").default
                      }
                      style={{ width: 400, height: 300 }}
                      alt="high chief"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default CareForLife;
