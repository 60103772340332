import React from "react";
import "../home/style.css";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import PageNavbar from "components/Navbars/PageNavbar.js";
import CharityFooter from "components/Footers/CharityFooter.js";

function AllCampaign() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
      <>
        <PageNavbar/>
        <div className="wrapper mt-5">
          <div className="section section-campaign">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-7 mt-5">
                  <h2 className="title-camp">Education and Scholarships</h2>
                  <p className="description">
                    Our Education and Scholarships Programme helps to broaden
                    access to and promote achievement in education especially for
                    students who would otherwise be shutout due to financial
                    constraints.
                    <br/>
                    We award scholarships to students from primary to university
                    levels. We also provide standard conducive learning
                    environments in urban and rural areas by building and
                    equipping schools. <br/>
                    Through this programme we are contributing to SDG, #4 which
                    seeks to, “Ensure inclusive and equitable quality education
                    and promote lifelong learning opportunities for all.”
                  </p>
                </div>
                <div className="col-md-12 col-lg-5">
                  <img
                      alt="..."
                      className="rounded img-fluid img-raised"
                      src={require("assets/img/education.png").default}
                  ></img>
                </div>
              </div>
            </div>
          </div>

          {/* Campaign list */}
          <div className="section">
            <Container>
              <Row className="single-campaign">
                <Col sm="12" lg="6">
                  <img
                      alt="..."
                      className="rounded img-fluid img-raised"
                      src={require("assets/img/camp1.png").default}
                  ></img>
                </Col>
                <Col sm="12" lg="6">
                  <h2 className="title">CONDUCIVE LEARNING ENVIRONMENTS</h2>
                  <p className="description">
                    Built and equipped science laboratories at Abonnema Girls’
                    Secondary School, Nyemoni Grammar School and Comprehensive
                    Secondary School, Abonnema Built a model nursery and primary
                    school furnished with modern facilities including a computer
                    lab for St. Augustine’s Nursery & Primary School, Abonnema.
                  </p>
                  <a href="#" className="text-info">
                    <i className="fa fa-arrow-right"/>
                    Read More
                  </a>
                </Col>
              </Row>
              <Row className="single-campaign mt-5">
                <Col sm="12" lg="6">
                  <img
                      alt="..."
                      className="rounded img-fluid img-raised"
                      src={require("assets/img/camp2.png").default}
                  ></img>
                </Col>
                <Col sm="12" lg="6">
                  <h2 className="title">CONDUCIVE LEARNING ENVIRONMENTS</h2>
                  <p className="description">
                    Built and equipped science laboratories at Abonnema Girls’
                    Secondary School, Nyemoni Grammar School and Comprehensive
                    Secondary School, Abonnema Built a model nursery and primary
                    school furnished with modern facilities including a computer
                    lab for St. Augustine’s Nursery & Primary School, Abonnema.
                  </p>
                  <a href="#" className="text-info">
                    <i className="fa fa-arrow-right"/>
                    Read More
                  </a>
                </Col>
              </Row>
            </Container>
          </div>
          <CharityFooter/>
        </div>
      </>
  );
}

export default AllCampaign;
