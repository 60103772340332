/**
 *  @copyright KARD.
 */

exports.core = {
    stage: 'test',
    cn: 'uk',
    o_id: 'oblulubriggs',
}


