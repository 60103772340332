import React from "react";
import "../home/style.css";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function SingleNews1() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "50vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/news/n1.jpg").default + ")",
            }}
          ></div>
        </div>

        <div className="section section-work">
          <Container>
            <Row>
              <Col sm="12">
                <h3 style={{ lineHeight: "35px" }}>
                  SEINYE LULU-BRIGGS APPOINTED CHAIR OF INT’L FUND FOR CIVIL
                  SOCIETY, CALLS FOR MORE FUNDING AT HOUSE OF LORDS TO COMBAT
                  GLOBAL HUMANITARIAN CRISES
                </h3>
                <Col lg="6" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    Renowned industrialist and champion of women’s rights, Dr.
                    Mrs. Seinye O. B. Lulu-Briggs, Chairman of the O. B.
                    Lulu-Briggs Foundation and Chief Executive Officer of Moni
                    Pulo (Petroleum Development) Limited, has been appointed the
                    Chairman of the Fund for the global Alliance of NGOs and
                    CSOs of South South Cooperation (ANCSSC) at a special
                    conference held at the House of Lords, Parliament of the
                    United Kingdom.
                  </div>
                </Col>
                <Col lg="6" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    Hosted by Baroness Pola Uddin, Member of the House of Lords,
                    the event which held at the Cholmondeley Room and Terrace of
                    the House of Lords, United Kingdom, brought together
                    diplomats, business executives, royalty, academia, and
                    members of the ANCSSC, the International Network of
                    450-member NGOs and CSOs from around the world who work in
                    collaboration with the United Nations Office for South-South
                    Cooperation (UNOSSC) to deepen the impact of civil society
                    in the Global South.
                  </div>
                </Col>
                <img
                  alt="..."
                  className="rounded img-fluid  my-4"
                  src={require("assets/img/news/allnews/n1.2.jpg").default}
                ></img>
                <Col lg="6" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    Dr. Seinye Lulu-Briggs thanked the ANCSSC for the honour to
                    chair its important fund and pledged her commitment at
                    ensuring that the global community is galvanised to better
                    fund the civil society in the Global South for the region’s
                    inclusive growth and development.
                  </div>
                </Col>
                <Col lg="6" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    Speaking on the importance of funding civil society, Dr.
                    Seinye said, “the emerging economies of the world are
                    bearing the brunt of both the global climate change crisis
                    and the ongoing unprecedented COVID-19 pandemic. And there
                    is no doubt that civil society organisations in the Global
                    South will be a vital part of the international development
                    community’s efforts to mitigate and create innovative
                    responses at the community level, going forward.
                  </div>
                </Col>
                <img
                  alt="..."
                  className="rounded img-fluid  my-3"
                  src={require("assets/img/news/allnews/n1.3.jpg").default}
                ></img>
                <p className="mt-5">
                  “In my role as the Chair of the Alliance Fund, I would like to
                  highlight the increasing engagement of the Global South in
                  multiple spheres of international development cooperation, and
                  there is significant expansion in the scope of the South South
                  cooperation. This has resulted in stronger economic bonds; but
                  I believe that much more can be done by invoking SDG goal 17
                  which focuses on achieving the SDGs through strengthening
                  solidarity, collaboration and partnership,” she emphasised.
                </p>

                <img
                  alt="..."
                  className="rounded img-fluids my-3"
                  src={require("assets/img/news/allnews/n1.jpg").default}
                ></img>
                <Col lg="6" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    Stressing further, Dr. Seinye said,” this is why it is
                    timely to focus on the importance of financing civil society
                    in the Global South. Now, more than ever, we need to take
                    action and stop the rhetoric. We are on the frontline of
                    building community resilience and responses to climate
                    change and to building-back-better in order to get back on
                    track to meeting the SDGs. We do not shy away from this task
                    as we tackle the sombre fact that the COVID-19 pandemic has
                    reversed 25 years of the progress made by the international
                    community in addressing endemic poverty. Civil society in
                    the Global South urgently requires access to financial
                    resources to strengthen and sustain their work.
                  </div>
                </Col>
                <Col lg="6" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    Earlier, host of the event Baroness Uddin praised the ANCSSC
                    for its efforts to bring together NGOs and CSOs to build
                    momentum and energy for organisations in the Global South in
                    order to develop resilience and self-reliance. According to
                    her, “it is through this collaboration and partnership that
                    communities and nations can flourish and be impactful. The
                    circle of poverty and injustices can be broken if as
                    individuals, organisations and communities will remain bound
                    by the common objectives of humanity and justice.”
                  </div>
                </Col>
                <img
                  alt="..."
                  className="rounded img-fluids my-3"
                  src={require("assets/img/news/allnews/n1.jpg").default}
                ></img>

                <Col lg="6" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    In his remarks His Excellency Sheikh (Dr.) Abdulaziz A.
                    Rahman H. A. Al-Thani, the CEO of the Islamic Council of
                    Donor Institutions and President of the ANCSSC speaking on
                    his vision for the ANCSSC, said his two goals were “to build
                    leaders in the humanitarian sector. I am proud that I am the
                    Chairman of building leaders in Qatar, my country. But I
                    want it to go everywhere especially to poor countries. My
                    second goal is to bring all people regardless of colour,
                    religion or countries to work together for humanity.” Sheikh
                    Al-Thani, who is a member of the ruling Al Thani Qatari
                    royal family, also urged civil society organisations to
                    embark on only projects that are sustainable, to achieve
                    enduring impact.
                  </div>
                </Col>

                <Col lg="6" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    Mrs. Efe Etomi, the Country President of the Nigerian
                    chapter of the African Women Lawyers Association (AWLA) said
                    the conference “underpins the importance of cooperation and
                    collaboration amongst NGOs and CSOs of South-South
                    Cooperation in tackling humanity’s greatest problem of the
                    unequal distribution of resources. The hardest hit in the
                    poverty chart are those who reside in the global south. This
                    is why it is easy to appreciate the O. B. Lulu Briggs
                    Foundation in its quest to reach out to willing partners to
                    collaborate on poverty alleviation programs,” assuring that
                    “the African Women Lawyers Association (Nigeria) will always
                    support noble efforts such as that shown by the O. B. Lulu
                    Briggs Foundation to drive active support for the vulnerable
                    and less privileged in society for 20 years and counting.{" "}
                  </div>
                </Col>

                <br />
                <img
                  alt="..."
                  className="rounded img-fluid  my-3"
                  src={require("assets/img/news/allnews/n1.4.jpg").default}
                ></img>
                <Col lg="6" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    Convener of the ANCSSC, Dr. Husna Ahmad, OBE, in her remarks
                    emphasised the need for global cooperation between civil
                    society and International Development partners to combat
                    both existing and looming humanitarian crises.
                    <br />
                    <br />
                    “COVID 19 has affected everyone globally, particularly the
                    South and then we’ve got the climate catastrophe coming in
                    nine years. What we must recognise is that we only have one
                    planet, and we have to consider our children and future
                    generations coming after us, about how they will look at us
                    and ask us what we did with our planet,” she said.
                  </div>
                </Col>
                <Col lg="6" className="side-by-side-p">
                  <div className="workParagraph my-3">
                    “The Alliance of NGOs and CSOs for South South Cooperation,”
                    she also noted “is a 450-member organisation under the
                    leadership of His Excellency Sheikh Abdulaziz, with Dr.
                    Seinye Lulu-Briggs as the Chair of the ACNSSC fund.” Dr.
                    Ahmad Husna also called for more support to civil society in
                    credit to the work they do in making a difference in the
                    global south, because, in her words “we cannot afford to
                    leave anyone behind.”
                  </div>
                </Col>
              </Col>
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default SingleNews1;
