import React from "react";
import "./style.css";
// reactstrap components
import { navigate } from "@reach/router";
import { Button, Container, Row, Col, Modal } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import HeroSection from "./hero_section.js";
import Acheivements from "./acheivements";
import Programs from "./program";
import DarkFooter from "components/Footers/DarkFooter.js";

import Cookie from "views/_common/cookies";

function LandingPage() {
  const [modalLive, setModalLive] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  React.useEffect(() => {
    setModalLive(true);
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <HeroSection />

        {/* Program */}
        <div className="section section-mission text-center">
          <Container>
            {/* <h4 style={{ color: "#45aae5" }}>Be a part of</h4> */}
            <h2 className="bd-title" style={{ paddingTop: "0px" }}>
              Our Programmes
            </h2>
            <h5 className="px-5 missionSub">
              We work to enhance the quality of life of people and communities
              by creating structured interventions which combat inequality and
              injustice. We want to create lasting change that empowers people
              and builds positive mindsets.
            </h5>
            <div className="team">
              <Row>
                <Col>
                  <div
                    className="team-player"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      navigate("/work/care-for-life");
                    }}
                  >
                    <img
                      alt="..."
                      className=" img-fluid"
                      src={
                        require("assets/img/mission/care-for-life.jpg").default
                      }
                    ></img>
                    <h4>Care for Life</h4>
                  </div>
                </Col>
                <Col>
                  <div
                    className="team-player"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      navigate("/work/free-medical-mission");
                    }}
                  >
                    <img
                      alt="..."
                      className=" img-fluid"
                      src={
                        require("assets/img/mission/free-medical-missions.jpg")
                          .default
                      }
                    ></img>
                    <h4>Free Medical Missions</h4>
                  </div>
                </Col>
                <Col>
                  <div
                    className="team-player"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      navigate("/work/education-scholarship");
                    }}
                  >
                    <img
                      alt="..."
                      className=" img-fluid"
                      src={require("assets/img/mission/education.jpg").default}
                    ></img>
                    <h4>Education & Scholarships </h4>
                  </div>
                </Col>
                <Col>
                  <div
                    className="team-player"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      navigate("/work/micro-credit");
                    }}
                  >
                    <img
                      alt="..."
                      className=" img-fluid"
                      src={
                        require("assets/img/mission/microfinance.jpg").default
                      }
                    ></img>
                    <h4>Micro-credit and Entrepreneurship </h4>
                  </div>
                </Col>
                <Col>
                  <div
                    className="team-player"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      navigate("/work/portable-water");
                    }}
                  >
                    <img
                      alt="..."
                      className=" img-fluid"
                      src={require("assets/img/mission/wash.jpg").default}
                    ></img>
                    <h4>Access to Potable Water and Sanitation</h4>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        {/* End Mission */}

        {/* Acheived */}
        <Acheivements />
        {/* End of Acheived */}

        {/* Donate */}
        {/* <div className="donate">
          <div className="embed-responsive embed-responsive-16by9">
             <iframe
              width="1350"
              height="480"
              src="https://www.youtube.com/embed/HI99T2ZLdsg"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="content-center">
            <Container>
              <Row>
                <Col>
                   <h2 className="bd-title">They Need Us</h2>
                  <p className="text-white">
                    O.B. LULU BRIGGS Foundation Calls for Greateer Attention to
                    Basic Needs as World Marks Days of Older Person
                  </p>
                  <Button
                    className="btn btn-lg btn-round donateBtn"
                    color="info"
                    href="/donation"
                  >
                    Donate
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        </div> */}
        {/* End of Donate */}
        <Cookie />
        {/* modal */}
        {/* <Button
          color="primary"
          type="button"
          onClick={() => setModalLive(true)}
        >
          Launch demo modal
        </Button> */}
        <Modal
          toggle={() => setModalLive(false)}
          isOpen={modalLive}
          className="modal-md"
        >
          <div className="modal-body">
            <h3 className="text-warning text-center">
              The website is currently under construction. Thank you for your
              patience.
            </h3>
          </div>
          {/* <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              onClick={() => setModalLive(false)}
            >
              Close
            </Button>
          </div> */}
        </Modal>
        {/* End Modal */}
        <DarkFooter />
      </div>
    </>
  );
}

export default LandingPage;
