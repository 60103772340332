

import React, {useEffect, useState} from 'react';
import {FormGroup, FormText, Input, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";

interface PasswordProps {
    requirement?:boolean,
    confirmPass?:boolean,
    label?:string,
    getPassword?:()=>void,
}

function Password({confirmPass,getPassword,label,requirement}:PasswordProps) {

    const [password, SetPassword] = useState('');
    const [confirmPassword, SetConfirmPassword] = useState(null);
    const [hasLength, Length] = useState(false);
    const [hasUpr, Upr] = useState(false);
    const [hasNum, Num] = useState(false);
    const [hasLow, Low] = useState(false);
    const [passMatch, PassMatch] = useState(false)
    const [showPass, ShowPass] = useState(false);
    const [showReq] = useState(requirement? requirement : false);
    const [showConfirmPass] = useState(confirmPass? confirmPass : false);

    function isValid() {
        return hasLength && hasUpr && hasLow && hasNum
    }

    useEffect(()=>{
        if (password.length)
        {
            Length(password.length>=8)
            Upr(/[A-Z]/.test(password))
            Low(/[a-z]/.test(password))
            Num(/\d/.test(password))
        }
    },[password])

    useEffect(()=>{
        const resp = {p:password}
        if (showConfirmPass)
        {
            PassMatch(password === confirmPassword)
            resp['cp'] = confirmPassword;
            resp['match'] = password === confirmPassword
        }
        if (showReq)
        {
            resp['valid'] = isValid()
        }
        if (getPassword)
            getPassword(resp)
    },[confirmPassword,password])


    return(
        <FormGroup>
            <label htmlFor="inputPassword1">{label?label:'Password'}</label>
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText style={{ padding: "10px" }}>
                        <i className="fa fa-key"/>
                    </InputGroupText>
                </InputGroupAddon>
                <Input
                    id="inputPassword1"
                    placeholder="Password"
                    type={showPass?"text":"password"}
                    value={password}
                    onChange={(e) => SetPassword(e.target.value.trim())}
                    required
                />
                <InputGroupAddon addonType="prepend" onClick={(e)=>{
                    e.preventDefault()
                    ShowPass(p=>!p)
                }}>
                    <InputGroupText style={{ padding: "10px" }}>
                        <i className={showPass?"fa fa-eye":"fa fa-eye-slash"}/>
                    </InputGroupText>
                </InputGroupAddon>
            </InputGroup>
            {
                showReq &&
                <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",flex:1}}>
                    <div style={{display:"flex",flexDirection: 'row',marginRight:8}}>
                        <div style={{display:"flex",flexDirection:"row"}}>
                            <i className="fa fa-check" aria-hidden="true" style={{color:hasLength?'#159957':'#ddd'}}/>
                            <p style={{fontSize:12,color:hasLength ? "green": "#aaa"}}>At least 8 characters</p>
                        </div>
                    </div>
                    <div style={{display:"flex",flexDirection: 'row',marginRight:8}}>
                        <div style={{display:"flex",flexDirection:"row"}}>
                            <i className="fa fa-check" aria-hidden="true" style={{color:hasUpr?'#159957':'#ddd'}}/>
                            <p style={{fontSize:12,color:hasUpr ? "green": "#aaa"}}>At least one uppercase character</p>
                        </div>
                    </div>
                    <div style={{display:"flex",flexDirection: 'row',marginRight:8}}>
                        <div style={{display:"flex",flexDirection:"row"}}>
                            <i className="fa fa-check" aria-hidden="true" style={{color:hasLow?'#159957':'#ddd'}}/>
                            <p style={{fontSize:12,color:hasLow ? "green": "#aaa"}}>At least one lowercase character</p>
                        </div>
                    </div>
                    <div style={{display:"flex",flexDirection: 'row',marginRight:8}}>
                        <div style={{display:"flex",flexDirection:"row"}}>
                            <i className="fa fa-check" aria-hidden="true" style={{color:hasNum?'#159957':'#ddd'}}/>
                            <p style={{fontSize:12,color:hasNum ? "green": "#aaa"}}>At least one number</p>
                        </div>
                    </div>
                </div>
            }
            {
                showConfirmPass &&
                    <div>
                        <label htmlFor="inputPassword1">Confirm Password</label>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText style={{ padding: "10px" }}>
                                    <i className="fa fa-key"/>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                id="inputPassword1"
                                placeholder="Password"
                                type={showPass?"text":"password"}
                                value={confirmPassword}
                                onChange={(e) => SetConfirmPassword(e.target.value.trim())}
                                required
                            />
                        </InputGroup>
                        <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",flex:1}}>
                            <div style={{display:"flex",flexDirection: 'row',marginRight:8}}>
                                <div style={{display:"flex",flexDirection:"row"}}>
                                    <i className="fa fa-check" aria-hidden="true" style={{color:passMatch?'#159957':'#ddd'}}/>
                                    <p style={{fontSize:12,color:passMatch ? "green": "#aaa"}}>Password Match</p>
                                </div>
                            </div>
                        </div>
                    </div>

            }

        </FormGroup>
    )
}

export default Password;
