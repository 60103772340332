import React from "react";
import "../home/style.css";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Input,
  FormGroup,
  Card,
} from "reactstrap";

import Loader from "react-js-loader";

import { useDispatch, useSelector } from "react-redux";

import { About_Volunteer } from "../../rdx/actions/about";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function Volunteer() {
  const dispatch = useDispatch();
  const orgId = useSelector((state) => state.__cfg.oId);

  // const [firstFocus, setFirstFocus] = React.useState(false);
  // const [lastFocus, setLastFocus] = React.useState(false);

  const [isBusy, IsBusy] = React.useState(false);

  const [name, Name] = React.useState("");
  const [email, Email] = React.useState("");
  const [phone, Phone] = React.useState("");
  // const [address, Address] = React.useState('');
  const [message, Message] = React.useState("");

  /**
   */
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  /**
   */
  const OnSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    Request().catch((err) => {});
  }; // OnSubmit

  /**
   */
  const Request = async () => {
    try {
      IsBusy(true);

      const resp_req = await dispatch(
        About_Volunteer({ name, email, phone, message })
      );

      console.log("Volunteer: Request: resp_req: ", resp_req);

      if (resp_req) {
        //
      }

      IsBusy(false);

      return {};
    } catch (err) {
      console.warn("Volunteer: Request: err: ", err);

      IsBusy(false);

      return Promise.reject(err);
    }
  }; // Request

  /**
   */
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "30vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/hero.jpg").default + ")",
            }}
          ></div>
          <div className="content-center">
            <Container>
              <h2 href="#" className="text-white title p-0">
                Volunteer
              </h2>
            </Container>
          </div>
        </div>
        <div className="section section-volunteer">
          <Container>
            <Row>
              <Col sm="12" md="6">
                <h3 className="title">Help Us Make a Difference</h3>
                <p>
                  We are passionate about supporting vulnerable communities and
                  enhancing the quality of their lives. Get in touch with us and
                  find out how you can use your skills to help support our
                  cause!
                </p>
                <h3 className="title">Our Vision</h3>
                <p>A world where people are free from despair.</p>
                <h3 className="title">Our Mission</h3>
                <p>
                  We work to enhance the quality of life of people and
                  communities by creating structured interventions which combat
                  inequality and injustice. We want to create lasting change
                  that empowers people and builds positive mindsets.
                </p>
              </Col>
              <Col sm="12" md="6">
                <h3 className="title mb-4">Get in touch with us</h3>

                <Form onSubmit={OnSubmit} className="volunteer">
                  <FormGroup>
                    <p htmlFor="name">Your Name *</p>
                    <Input
                      aria-describedby="emailHelp"
                      id="name"
                      placeholder="Enter Name"
                      type="text"
                      required
                      value={name}
                      onChange={(e) => Name(e.target.value)}
                    ></Input>
                  </FormGroup>

                  <FormGroup>
                    <p htmlFor="exampleInputEmail1">Email address *</p>
                    <Input
                      aria-describedby="emailHelp"
                      id="exampleInputEmail1"
                      placeholder="Enter email"
                      type="email"
                      required
                      value={email}
                      onChange={(e) => Email(e.target.value)}
                    ></Input>
                  </FormGroup>

                  <FormGroup>
                    <p htmlFor="phone">Phone Number *</p>
                    <Input
                      aria-describedby="emailHelp"
                      id="phone"
                      placeholder="Enter Phone"
                      type="text"
                      required
                      value={phone}
                      onChange={(e) => Phone(e.target.value)}
                    ></Input>
                  </FormGroup>

                  <FormGroup>
                    <p htmlFor="text">Write to us</p>
                    <Input
                      id="text"
                      rows="3"
                      type="textarea"
                      value={message}
                      onChange={(e) => Message(e.target.value)}
                    ></Input>
                  </FormGroup>

                  <Button color="info" type="submit" style={{ width: "100%" }}>
                    SEND
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>

      <>
        {isBusy && (
          <div>
            <div
              className="modal"
              style={{
                // margin: '0 auto'
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#d1cccc80",
              }}
            >
              <Loader
                type="box-rectangular"
                bgColor={"#469b5a"}
                title={"Processing ..."}
                color={"#FFFFFF"}
                size={150}
              />
            </div>
          </div>
        )}
      </>
    </>
  );
}

export default Volunteer;
