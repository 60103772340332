import React from "react";
import "../home/style.css";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

// core components

const items = [
  {
    src: require("assets/img/single-campaign.png").default,
    altText: "Nature, United States",
    caption: "Nature, United States",
    created: "14 days ago",
    description: "Rita made 76K to help traise these children",
  },
  {
    src: require("assets/img/single-campaign.png").default,
    altText: "Nature, United States",
    caption: "Nature, United States",
    created: "14 days ago",
    description: "Rita made 76K to help traise these children",
  },
];

function CarouselSection() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <div className="carousel-section carousel-campaign" id="carousel">
        <Container>
          <h2 className="title">Featured Programs </h2>
          <Row className="justify-content-center">
            <Col sm="9" lg="10" md="10">
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
              >
                <CarouselIndicators
                  items={items}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {items.map((item) => {
                  return (
                    <CarouselItem
                      onExiting={onExiting}
                      onExited={onExited}
                      key={item.src}
                    >
                      <img
                        src={item.src}
                        alt={item.altText}
                        style={{ maxHeight: "300px" }}
                      />
                      <div className="campaign-carousel-caption d-none d-md-block">
                        <h5 className="text-white">{item.caption}</h5>
                        <p className="description text-white">{item.created}</p>
                      </div>
                      <div className="campaign-meta">
                        <Row>
                          <h3
                            className="col-sm-12 col-md-12 col-lg-12"
                            style={{ marginBottom: "0" }}
                          >
                            76$
                            <span className="muted">
                              {" "}
                              Raised of $15000 goal
                            </span>
                            <span className="pull-right muted">
                              Medical, Illness & healing
                            </span>
                          </h3>
                        </Row>
                        <div className="progress col-sm-12">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: "70%" }}
                            aria-valuenow="50"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <div className="followers">
                          <Row>
                            <h3 className="col-sm-6 col-md-12 col-lg-12">
                              <span
                                style={{ fontSize: "16px", color: "#002147" }}
                              >
                                2.4K donors 2K Shares 5K followers
                              </span>
                              <span className="muted pull-right">
                                1557 people just donated
                              </span>
                            </h3>
                          </Row>
                          <div className="button">
                            <button className="btn btn-info btn-lg col-sm-8">
                              <i className="fa fa-dollar text-white" /> Donate
                              Now
                            </button>
                            <button className="btn btn-outline-default btn-lg col-sm-3">
                              Share
                            </button>
                          </div>
                        </div>
                      </div>
                    </CarouselItem>
                  );
                })}
                <a
                  className="carousel-control-prev"
                  data-slide="prev"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    previous();
                  }}
                  role="button"
                >
                  <i className="now-ui-icons arrows-1_minimal-left"></i>
                </a>
                <a
                  className="carousel-control-next"
                  data-slide="next"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    next();
                  }}
                  role="button"
                >
                  <i className="now-ui-icons arrows-1_minimal-right"></i>
                </a>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default CarouselSection;
