/**
 *	@copyright	KARD.
 */

/**
 */
export default ( state = {
    info: {},
}, action ) =>
{
    switch( action.type )
    {
        case 'charity:set-info':
            return {...state, info: action.payload}
        default:
            return state
    }   // switch ...
}   // export default



