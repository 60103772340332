import React from "react";

const OptionItem = ({item, isSelected, isExp, isDisable, OnSelect }) => {
    const title = item?.p?.t || item?.ci?.t;
    const details = item?.p?.d || item?.ci?.d;
    return(
        <>
            <div
                 className="rounded mx-3 p-2 my-4"
                 style={{
                     border: "1px solid #DDE1E6",
                     backgroundColor:isSelected? "#EFF8FF": "white",
                 }}
                 onClick={(e) => {
                     e.preventDefault();
                     e.stopPropagation();
                     OnSelect(item);
                 }}>
                <h3
                    className="text-left"
                    style={{ fontSize: 18, color: "#060c27" }}
                >
                    {title}
                </h3>

                <h5 style={{ color: "#51586c", fontSize: "14px" }}>{details}</h5>
            </div>
        </>
    )

}

const PackItem = ({ item, isSelected, OnSelect }) => {
    return (
        <div key={item.tn}>
            <div
                className="border border-primary rounded m-2 p-2"
                style={{
                    backgroundColor:
                        isSelected ? "lightgreen" : null,
                }}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    OnSelect(item);
                }}
            >
                <h3
                    style={{ fontSize: "20px", color: isSelected ? "#fff" : "#060c27" }}
                >
                    {item.n}
                </h3>
                {item?.a > 0 ? (
                    <h5
                        style={{ color: isSelected ? "#fff" : "#060c27", fontSize: "16" }}
                    >
                        {"£"}
                        {item.a} - {"rec" === item.p ? "Recurring" : "Once"}
                    </h5>
                ) : null}
            </div>
        </div>
    );
};

export {OptionItem, PackItem}
