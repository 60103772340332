import React from "react";

export const FormButton = ({text,selected,onPress, disabled}) => {
  return(
      <div className="form-group">
          <button
              className="form-control border-info"
              style={{
                  backgroundColor:selected && "#2680c2",
                  color: selected && "#fff",
              }}
              disabled={disabled}
              onClick={(e) => {
                  if (e){
                      e.preventDefault();
                      e.stopPropagation();
                  }
                  onPress()
              }}
          >
              {text}
          </button>
      </div>
  )
}
