/**
 *	@copyright	KARD..
 */

import * as AWS from '../../api/AWS'
import * as Cfg from './cfg'
import {
    Time
} from "../../api/Utils";
/**
 */
export const Profile_Init = (p) =>
{
    return async ( d, gs ) =>
    {
        try
        {

            const aws_lambda = new AWS.Lambda( {} )
            const aws_core = new AWS.Core( {} )

            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()

            // console.log("cur_usr",cur_usr)

            const lambda_name = d( Cfg.Lambda('app') )

            // console.log("lambda_name",lambda_name)

            const p_list = {
                actType: 'prof',
                act: 'prof:init',
                i: gs().__cfg.oId,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                    name: p.name
                }

            }

            // console.log( 'actions/profile: Profile_Init: p_list: ', p_list )

            const resp_camp = await aws_lambda.Invoke( p_list, lambda_name )

            // console.log( 'actions/profile: Profile_Init: resp: ', resp_camp )

            return resp_camp

        }
        catch( err )
        {
            console.warn( 'actions/profile: Profile_Init: err: ', err )
            return Promise.reject( err )
        }
    }
}   // Profile_Init
/**
 */
export const Profile_Get_All = ( prof_obj ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            let last_key = null;

            let date_st = new Date();
            let date_end = new Date();

            date_st.setDate(date_st.getDate() - 30);
            date_st.setHours(0);
            date_st.setMinutes(0);
            date_st.setSeconds(0);

            date_end.setHours(0);
            date_end.setMinutes(0);
            date_end.setSeconds(0);

            const dt_st = Time.Epoch(date_st.getTime());
            const dt_end = Time.Epoch(date_end.getTime());

            const aws_lambda = new AWS.Lambda( {} )
            const aws_core = new AWS.Core( {} )

            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()

            // console.log("cur_usr",cur_usr)

            const lambda_name = d( Cfg.Lambda('app') )

            // console.log("lambda_name",lambda_name)

            const p_list = {
                actType: 'prof',
                act: 'prof:get',
                i: gs().__cfg.oId,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
                data: {
                    dt_st,
                    dt_end,
                    last_key,
                    n_elem: 20,
                },
                u: cur_usr.username
            }

            // console.log( 'actions/profile: Profile_Get_All: p_list: ', p_list )

            const resp_camp = await aws_lambda.Invoke( p_list, lambda_name )
            //
            // console.log( 'actions/profile: Profile_Get_All: resp_camp: ', resp_camp )

            return resp_camp

        }
        catch( err )
        {
            console.warn( 'actions/profile: Profile_Get_All: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Profile_Get_All
/**
 */
export const Profile_Get_Info = ( prof_obj ) =>
{
    return async ( d, gs ) =>
    {
        try
        {

            const aws_lambda = new AWS.Lambda( {} )
            const aws_core = new AWS.Core( {} )

            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()

            // console.log("cur_usr",cur_usr)

            const lambda_name = d( Cfg.Lambda('app') )

            // console.log("lambda_name",lambda_name)

            const p_list = {
                actType: 'prof',
                act: 'prof:get:info',
                i: gs().__cfg.oId,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                }
            }

            // console.log( 'actions/profile: Profile_Get_Info: p_list: ', p_list )

            const resp_camp = await aws_lambda.Invoke( p_list, lambda_name )
            //
            // console.log( 'actions/profile: Profile_Get_Info: resp_camp: ', resp_camp )

            d( _Prof_Info(resp_camp) )


            return resp_camp

        }
        catch( err )
        {
            console.warn( 'actions/profile: Profile_Get_Info: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Profile_Get_Info

export const SaveProfileImage = (params) =>
{
    return async (d,gs)=> {
        try {
            const aws_lambda = new AWS.Lambda( {} )
            const aws_core = new AWS.Core( {} )
            const cur_usr = await aws_core.currentuser()

            if (!cur_usr)
            {
                throw new Error("User not found!")
            }

            const lambda_name = d( Cfg.Lambda('app') )

            const p_list = {
                actType: 'prof',
                act: 'prof:img-update',
                c_id: gs().__cfg.oId,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },

                data: {
                    img:{
                        f:params.url,
                        t:""
                    },
                    u:params.u
                },
            }

            // console.log( 'actions/profile: SaveProfileImage: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, lambda_name )

            // console.log( 'actions/profile: SaveProfileImage: resp_list: ', resp_list )

            //
            return resp_list

        } catch (e) {
            console.warn("action/profile: SaveProfileImage:error", e)
            return Promise.reject(e);
        }
    }
}
/**
 */
const _Prof_Info = ( data ) =>
{
    return {
        type: 'prof:info',
        payload: data,
    }
}   // _Auth_Status
/**
 */
export const Profile_Get_Donation = ( prof_obj ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            let last_key = null;
            //
            // let date_st = new Date();
            // let date_end = new Date();
            //
            // date_st.setDate(date_st.getDate() - 30);
            // date_st.setHours(0);
            // date_st.setMinutes(0);
            // date_st.setSeconds(0);
            //
            // date_end.setHours(0);
            // date_end.setMinutes(0);
            // date_end.setSeconds(0);

            // const dt_st = Time.Epoch(date_st.getTime());
            // const dt_end = Time.Epoch(date_end.getTime());

            const aws_lambda = new AWS.Lambda( {} )
            const aws_core = new AWS.Core( {} )

            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()

            // console.log("cur_usr",cur_usr)

            const lambda_name = d( Cfg.Lambda('app') )

            // console.log("lambda_name",lambda_name)

            const p_list = {
                actType: 'prof',
                act: 'prof:get:donation',
                i: gs().__cfg.oId,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
                data: {
                    // dt_st,
                    // dt_end,
                    // last_key,
                    n_elem: 20,
                }
            }

            // console.log( 'actions/profile: Profile_Get_Donation: p_list: ', p_list )

            const resp_camp = await aws_lambda.Invoke( p_list, lambda_name )
            //
            // console.log( 'actions/profile: Profile_Get_Donation: resp_camp: ', resp_camp )

            return resp_camp

        }
        catch( err )
        {
            console.warn( 'actions/profile: Profile_Get_Donation: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Profile_Get_Donation
/**
 */
export const Profile_Get_Campaign = ( prof_obj ) =>
{
    return async ( d, gs ) =>
    {
        try
        {
            let last_key = null;

            let date_st = new Date();
            let date_end = new Date();

            date_st.setDate(date_st.getDate() - 30);
            date_st.setHours(0);
            date_st.setMinutes(0);
            date_st.setSeconds(0);

            date_end.setHours(24);
            date_end.setMinutes(0);
            date_end.setSeconds(0);

            const dt_st = Time.Epoch(date_st.getTime());
            const dt_end = Time.Epoch(date_end.getTime());

            const aws_lambda = new AWS.Lambda( {} )
            const aws_core = new AWS.Core( {} )

            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()

            // console.log("cur_usr",cur_usr)

            const lambda_name = d( Cfg.Lambda('app') )

            // console.log("lambda_name",lambda_name)

            const p_list = {
                actType: 'prof',
                act: 'prof:get:campaign',
                i: gs().__cfg.oId,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
                data: {
                    dt_st,
                    dt_end,
                    last_key,
                    n_elem: 20,
                }
            }

            // console.log( 'actions/profile: Profile_Get_Campaign: p_list: ', p_list )

            const resp_camp = await aws_lambda.Invoke( p_list, lambda_name )
            //
            // console.log( 'actions/profile: Profile_Get_Campaign: resp_camp: ', resp_camp )

            return resp_camp

        }
        catch( err )
        {
            console.warn( 'actions/profile: Profile_Get_Campaign: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Profile_Get_Campaign


/**
 */
export const Profile_Update_Info= ( p ) =>
{
    return async ( d, gs ) =>
    {
        try
        {

            const aws_lambda = new AWS.Lambda( {} )
            const aws_core = new AWS.Core( {} )

            const cur_usr = await ( async () => {
                try
                {
                    return await aws_core.currentuser()
                }
                catch( err )
                {
                    return { attributes: {}, username: null }
                }
            } )()

            // console.log("cur_usr",cur_usr)

            const lambda_name = d( Cfg.Lambda('app') )

            // console.log("lambda_name",lambda_name)

            const p_list = {
                actType: 'prof',
                act: 'prof:upd:info',
                i: gs().__cfg.oId,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
                data:{
                    n: p.name,
                    a: p.addr,
                    p: p.phone
                }
            }

            // console.log( 'actions/profile: Profile_Get_Info: p_list: ', p_list )

            const resp_camp = await aws_lambda.Invoke( p_list, lambda_name )
            //
            // console.log( 'actions/profile: Profile_Get_Info: resp_camp: ', resp_camp )

            return resp_camp

        }
        catch( err )
        {
            console.warn( 'actions/profile: Profile_Get_Info: err: ', err )

            return Promise.reject( err )
        }
    }
}   // Profile_Get_Info



