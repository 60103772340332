import React from "react";
import "../home/style.css";
// reactstrap components
import { navigate } from "@reach/router";
import { Container, Row, Col } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function FreeMedicalMission() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "50vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" +
                require("assets/img/works/free-medical/medical-2.JPG").default +
                ")",
            }}
          ></div>

          <div className="content-center">
            <Container>
              <h3 href="#" className="text-white ">
                {/* Mission and Free Medical Interventions */}
                Free Medical Mission
              </h3>
              {/* <a
                className="btn btn-lg text-white pt-3 cmpBtn"
                color="info"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  navigate("/donation/ext/1642076782/1642077335163");
                }}
              >
                Donate
              </a> */}
            </Container>
          </div>
        </div>

        <div className="section ">
          <Container>
            <Row>
              <Col sm="9" className="mx-auto">
                <p>
                  Good health is universally recognized as the greatest asset of
                  any society and is considered a fundamental human right. The
                  United Nations’ Sustainable Development Goals provide a
                  holistic framework through which poverty, development and
                  socio-economic issues can be addressed. Among their 17 SDGs
                  drawn up for nations and organisations to follow, is{" "}
                  <span className="font-weight-bold">
                    SDG 3: To ensure healthy lives and promote wellbeing for all
                    at all ages.
                  </span>
                </p>
                <p>
                  Improving the quality of health and promoting health awareness
                  across the Niger delta and beyond, is an integral part of our
                  programming. A population is only as strong as its healthiest
                  individuals – supporting healthcare systems and building the
                  capacity of healthcare workers is crucial to the success,
                  prosperity and productivity of a society. Through our Free
                  Medical Missions, we have been able to carry out vital,
                  life-saving interventions to help the most vulnerable. Since
                  2005, we have taken comprehensive high-quality health
                  services, including surgeries, to the doorstep of 132,668 men,
                  women and children in rural and semi-urban Niger Delta
                  communities in Akwa Ibom, Bayelsa, Cross River and Rivers
                  State. The inmates of the Port Harcourt and Degema Prisons
                  have also benefitted from the Foundation’s Free Medical
                  Missions.
                </p>
                <img
                  alt="..."
                  className="rounded img-fluid center-img"
                  src={
                    require("assets/img/works/free-medical/medical-2.JPG")
                      .default
                  }
                ></img>
                <p className="mt-5">
                  The state of healthcare across Nigeria remains of serious
                  concern. Nigeria’s health care system has gone from being
                  comparable to the rest of the world in the 1970s and early
                  1980s, to one of the world’s most underfunded and least
                  robust. There are multiple problems surrounding healthcare in
                  Nigeria, one of which being a shortage of doctors; with 3.8
                  doctors per 10,000 people. The Global Goals target, in the
                  SDGs, is to have a minimum of 1 doctor per 1,000 people. This
                  is ambitious and can only be made possible through
                  organisations, communities, and individuals taking action to
                  ensure the health inequalities in Nigeria, as well as across
                  the Global South are decreased.
                </p>
                <p>
                  Facilities, healthcare workers and staff are overwhelmed and
                  unsupported, particularly in rural communities across the
                  Niger Delta. Therefore accessing quality and timely care is
                  made incredibly difficult, and in some cases, impossible. Many
                  households and individuals are unable to afford the high cost
                  of expenses in both the public and private healthcare systems.
                  Many simply delay, or worse, do not seek healthcare at all.
                  There is therefore a heavy burden of unmet medical needs on
                  families and communities across the country.
                </p>

                <img
                  alt="..."
                  className="rounded img-fluid center-img my-5"
                  src={
                    require("assets/img/works/free-medical/medical-3.JPG")
                      .default
                  }
                ></img>
                <p className="mt-5">
                  In 2019, Nigeria was responsible for 20% of all maternal
                  mortality globally and only has approximately 24,000
                  hospitals. This is why the funding and establishment of health
                  services and medical missions is of vital importance. There is
                  a gap waiting to be filled – here at O.B. Lulu-Briggs
                  Foundation, we are working hard to fill that gap and support
                  the most vulnerable with life-saving interventions.
                </p>
                <h4 className="mt-0">
                  During our five-day Medical missions, medical and non-medical
                  volunteers provide the following services:
                </h4>
                <div className="d-flex">
                  <Col lg="6" className="p-0">
                    <ul className="list-group pl-3" style={{ fontSize: 18 }}>
                      <li className="font-weight-bold mb-2">
                        Out-Patient consultations
                      </li>
                      <li className="font-weight-bold mb-2">
                        Counseling/Health education
                      </li>
                      <li className="font-weight-bold mb-2">Pediatric care</li>
                      <li className="font-weight-bold mb-2">Dental care</li>
                      <li className="font-weight-bold mb-2">Physiotherapy</li>
                      <li className="font-weight-bold mb-2">
                        Eye evaluation and treatment
                      </li>
                      <li className="font-weight-bold mb-2">
                        On-site laboratory
                      </li>
                      <li className="font-weight-bold mb-2">
                        Sonogram scanning
                      </li>
                      <li className="font-weight-bold mb-2">
                        Dispensing Eyeglasses
                      </li>
                      <li className="font-weight-bold mb-2">
                        Surgical procedures
                      </li>
                    </ul>

                    {/* <a
                      className="btn btn-lg text-white pt-3 cmpBtn"
                      color="info"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        navigate("/donation/ext/1642076782/1642077335163");
                      }}
                    >
                      Donate
                    </a> */}
                  </Col>

                  {/* <Col lg="6">
                    <ul className="list-group">
                      <li className="list-group-item list-group-item-info font-weight-bold">
                        PEye evaluation and treatmen
                      </li>
                      <li className="list-group-item list-group-item-info font-weight-bold">
                        On-site laboratory
                      </li>
                      <li className="list-group-item list-group-item-info font-weight-bold">
                        Sonogram scanning
                      </li>
                      <li className="list-group-item list-group-item-info font-weight-bold">
                        Dispensing Eyeglasses
                      </li>
                      <li className="list-group-item list-group-item-info font-weight-bold">
                        Surgical procedures
                      </li>
                    </ul>
                  </Col> */}
                </div>
              </Col>
            </Row>

            <Row>
              <Col sm="9" className="mx-auto">
                <div className="embed-responsive embed-responsive-16by9 mb-2">
                  <iframe
                    width="1280"
                    height="720"
                    src="https://www.youtube.com/embed/w2VtMi8Dq6Y"
                    title="1W7A2375"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default FreeMedicalMission;
