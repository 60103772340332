import * as Cfg from "../../_cfg";

const orgId = Cfg.core.o_id;

export const PrepParams = (state) => {

    const donorParam = {
        p_no:  [state.donorInfo.phone.cn, state.donorInfo.phone.n].join(''),
        name: state.donorInfo.name,
        addr: state.donorInfo.address,
        phone: state.donorInfo.phone,
        email: state.donorInfo.email,
    };
    const donationParams = {
        ...donorParam,
        type:state?.type||"ext",
        pr_i:state.project.i,
        pr_n:state.project.tn,
        pr_t:state.project.p.t,
        cp_i:state.campaign.i,
        cp_n:state.campaign.tn,
        cp_t:state.campaign.ci.t,
        pck:{
            tn: state.pack.tn,
            a: state.pack.a,
            p: state.pack.p,
        },
        dont_t:null,
        paym:state.paymentMethod,
        c_ga:state.giftAid,
        c_ch:state.consent,
        s:"pending",
        isAnon:state.isAnonymous,
        msg:state.message
    }
    if (state.pack.p === "rec" && state.pack.p_i){
        donationParams.pack["p_i"] = state.pack.p_i;
    }
    if (state.donorInfo?.email?.length>0)
    {
        const emailParams = {
            to: state.donorInfo.email,
        }
        const subsParams = {
            email: state.donorInfo.email,
            topic:[orgId, state.project.tn, state.campaign.tn].join("/"),
            pr_t:state.project.p.t,
            cp_t:state.campaign.ci.t,
        }

        return {donorParam,donationParams,emailParams,subsParams}
    }
    return {donorParam,donationParams}
}

export const CreateDonation = async(donorParam, donation) => {
    // const dispatch = useDispatch();

    try {
        // const {donor} = await Donor_New(donorParam);
        // console.log(donor);
    } catch (e) {
        console.log("process_donation/CreateDonation: error:", e);
        return Promise.reject(e);
    }
}
