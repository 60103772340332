import React from "react";
import "../home/style.css";
// reactstrap components
import { navigate } from "@reach/router";
import { Container, Row, Col } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function Covid() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "50vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" +
                require("assets/img/works/education/edu-3.jpg").default +
                ")",
            }}
          ></div>
          <div className="content-center">
            <Container>
              <h3 href="#" className="text-white">
                Nigerian Law School Grant Awards
              </h3>
              {/* <a
                className="btn btn-lg text-white pt-3 cmpBtn"
                color="info"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  navigate("/donation/ext/1642076995/1642077071221");
                }}
              >
                Donate
              </a> */}
            </Container>
          </div>
        </div>

        <div className="section">
          <Container>
            <Row>
              <Col sm="9" className="mx-auto">
                <p>
                  Since 2009, through its Rivers State Nigerian Law School
                  Students Grant Awards, the Foundation has awarded a brand-new
                  laptop and N120,000 cash grant to eligible law students of
                  Rivers State origin to participate fully in the Nigerian Law
                  School. To date, a total of 691 Law School students have
                  benefited from the Foundation’s Rivers State Nigerian Law
                  School Students Grant Awards Annual Ceremony which features a
                  leading legal luminary as a guest speaker to inspire the
                  trainee lawyers to excel at the Law School and in whatever
                  field of endeavor they eventually choose.
                </p>
              </Col>
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default Covid;
