

/**
 */
export const stripe = {
    key: {
        live: 'pk_live_CsZHj0WJH3LXOllagfsTQVJO',
        test: 'pk_test_g9kLXsq7bwsQjllLFWMpJqEp',
        // live: 'pk_live_51JyfsdKdbv2X8jmA3cJNk0q3uAITvPjBj6ufie4Dh8Ht5hcLOtK7VNFf7xW0ZqLSlnH4TRe5vHchcgnNujgoLNM90028aNBbiw',
        // test: 'pk_test_51JyfsdKdbv2X8jmA4tCsVHzoiEDv0ankfzEyWwXq5oQhfJvosFp3BBf9YiSOMTTtD3ju7LG8QCOJbor8vIbD417t00Gves5QBP',
    },
    v: '2019-12-03',
}



