import {Core, Lambda} from "../../api/AWS";
import * as Cfg from './cfg'

/**
 */
function getSignedUrl(params)
{
    return async (d, gs)=>
    {
        try
        {
            const aws_lambda = new Lambda( {} )
            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            if (!cur_usr)
            {
                throw new Error("User not found!")
            }

            const lambda_name = d( Cfg.Lambda('app') )

            const p_list = {
                actType: 'storage',
                act: 'storage:get-signed-url',
                c_id: gs().__cfg.oId,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
                data: {
                    // bucket:params.bucket,
                    key:params.key,
                    contentType:params.contentType
                },
            }

            console.log( 'actions/storage: getSignedUrl: p_list: ', p_list )

            const resp_list = await aws_lambda.Invoke( p_list, lambda_name )

            console.log( 'actions/storage: getSignedUrl: ', resp_list )

            return {success:true, ...resp_list}
        }
        catch (e)
        {
            console.warn("actions/storage: getSignedUrl:error",e)

            return Promise.reject(e);
        }
    }
}

/**
 */
function _StoreInit(params)
{
    return async (d, gs)=>
    {
        try
        {
            const aws_lambda = new Lambda( {} )
            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            if (!cur_usr)
            {
                throw new Error("User not found!")
            }

            const lambda_name = d( Cfg.Lambda('app') )

            const p_store = {
                actType: 'storage',
                act: 'storage:upd-init',
                c_id: gs().__cfg.oId,
                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
                data: {
                    // bucket:params.bucket,
                    // key:params.key,
                    tp: params.i_type,
                    g: params.gallery,
                    gi: params.gallery_id,

                    fn: params.name,    // file name
                    ft: params.type,
                    // contentType:params.contentType
                    ct: params.contentType,
                },
            }

            console.log( 'actions/storage: _StoreInit: p_store: ', p_store )

            const resp_store = await aws_lambda.Invoke( p_store, lambda_name )

            console.log( 'actions/storage: _StoreInit: resp_store: ', resp_store )

            return {success:true, ...resp_store}
        }
        catch (e)
        {
            console.warn("actions/storage: _StoreInit:error",e)

            return Promise.reject(e);
        }
    }
}   // _StoreInit

/**
 */
const _StoreEnd = ( params ) =>
{
    return async (d, gs)=>
    {
        try
        {
            const aws_lambda = new Lambda( {} )
            const aws_core = new Core( {} )
            const cur_usr = await aws_core.currentuser()

            if (!cur_usr)
            {
                throw new Error("User not found!")
            }

            const lambda_name = d( Cfg.Lambda('app') )

            const p_store = {
                actType: 'storage',
                act: 'storage:upd-end',
                c_id: gs().__cfg.oId,

                usr: {
                    ...cur_usr.attributes,
                    username: cur_usr.username,
                },
                data: {
                    ii: params.ii,
                    // bucket:params.bucket,
                    // key:params.key,
                    // contentType:params.contentType
                },
            }

            console.log( 'actions/storage: _StoreEnd: p_store: ', p_store )

            const resp_list = await aws_lambda.Invoke( p_store, lambda_name )

            console.log( 'actions/storage: _StoreEnd: ', resp_list )

            return {success:true, ...resp_list}
        }
        catch (e)
        {
            console.warn("actions/storage: _StoreEnd:error",e)

            return Promise.reject(e);
        }
    }
}   // _StoreEnd

/**
 */
export const UploadFile2 = ( params ) =>
{
    return async (d, gs) =>
    {
        try
        {
            const file_p = {
                name: params.name,
                type: params.type,
                // key: params.key,
                contentType: params.contentType,
                // bucket: params.bucket,
                // region:auth_cred?._clientConfig?.region||auth_cred.identityId.split(':')[0],
                file:params.file,

                i_type: params.i_type,
                gallery: params.gallery,
            }

            console.log('action/storage: UploadFile2: file_p: ', file_p);

            const resp_init = await d(_StoreInit(file_p))

            console.log('action/storage: UploadFile2: resp_init: ', resp_init);

            const resp_save = await Save2S3({url: resp_init.url, file: params.file, contentType: params.contentType});

            console.log('action/storage: UploadFile2: resp_save: ', resp_save);

            if( resp_save )
            {
                //
            }

            const resp_end = await d(_StoreEnd({...file_p, ii: resp_init.ii}))

            console.log('action/storage: UploadFile2: resp_end: ', resp_end);

            return {}
        }
        catch (err)
        {
            console.warn('action/storage: UploadFile2: err', err);

            return Promise.reject(err);
        }
    }
}   // UploadFile2

/**
 */
export const uploadFile = (params) =>
{
    return async (d, gs) =>
    {
        try
        {
            // const aws_core = new Core( {} );
            // const auth_cred =  await aws_core.Credentials();

            // console.log(auth_cred)

            // const file_p = {
                
            // }

            const p_init = {
                name: params.name,
                type: params.type,
                // key: params.key,
                contentType: params.contentType,
                // bucket: params.bucket,
                // region:auth_cred?._clientConfig?.region||auth_cred.identityId.split(':')[0],
                file:params.file,

                i_type: params.i_type,
                gallery: params.gallery,
                gallery_id: params.gallery_id,
            }

            console.log('actions/storage: UploadFile: p_init: ', p_init)

            const resp_init = await d(_StoreInit(p_init))

            console.log('actions/storage: UploadFile: resp_init: ', resp_init)

            const file_p = {
                name: params.name,
                key: params.key,
                contentType: params.contentType,
                // bucket: params.bucket,
                // region:auth_cred?._clientConfig?.region||auth_cred.identityId.split(':')[0],
                // file:params.file
            }

            // console.log('actions/storage: UploadFile: p_list: ', file_p)

            const {success, url} = await d(getSignedUrl(file_p))

            // console.log('actions/storage: UploadFile: ', success,url)

            if( !success )
            {
                throw new Error( 'Failed to get signed url' )
            }

            // file_p['url'] = url

            // const resp = await Save2S3(file_p);
            const resp = await Save2S3({
                url: url,
                file: params.file,
                contentType: params.contentType,
            });

            // console.log('actions/storage: UploadFile: ', resp)

            // if (resp.success)
            // {
            //     return {...resp}
            // }

            const p_end = {
                ii: resp_init.ii
            }

            console.log('actions/storage: UploadFile: p_end: ', p_end)

            const resp_end = await d(_StoreEnd(p_end))

            console.log('actions/storage: UploadFile: resp_end: ', resp_end)

            return { ...resp }
        }
        catch (e)
        {
            console.warn('action/storage: UploadFile:error', e);

            return Promise.reject(e);
        }
    }
}

/**
 */
const Save2S3 = async (p) =>
{
    try
    {
        console.log('action/storage: Save2S3: p: ', p);

        const resp = await fetch(p.url, {
            method: "PUT",
            headers: {
                "Content-Type": p.contentType
            },
            body: p.file
        });

        console.log('action/storage: Save2S3: resp: ', resp);

        if (resp.status === 200)
        {
            const url = resp.url.split('?')[0]

            // return resolve({success:true,url})
            return {success:true,url}
        }
        else
        {
            // throw new Error( resp.statusText )
            return Promise.reject( {success:false, error: resp.statusText} )

            // return reject({success:false,error:resp.statusText})
        }
    }
    catch (e)
    {
        console.warn('action/storage: Save2S3:error',e)

        return Promise.reject(e)
    }
}

