import React from "react";
import "../home/style.css";

// reactstrap components
// reactstrap components
import { Card, Container, Row, Col, Button, Modal } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar";
import DarkFooter from "components/Footers/DarkFooter.js";

function Team() {
  const [modalBio1, setModalBio1] = React.useState(false);
  const [modalBio2, setModalBio2] = React.useState(false);
  const [modalBio3, setModalBio3] = React.useState(false);
  // const [firstFocus, setFirstFocus] = React.useState(false);
  // const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header" style={{ minHeight: "30vh" }}>
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/hero.jpg").default + ")",
            }}
          ></div>
          <div className="content-center">
            <Container>
              <h2 href="#" className="text-white title p-0">
                Our Team
              </h2>
            </Container>
          </div>
        </div>

        <div className="section section-team text-center">
          <Container>
            <p>
              We have a passionate and committed team based in our Port Harcourt
              office and in the field. Our programming is complemented by a pool
              of medical and non-medical volunteers.
            </p>
            <h2 className="title">Board of Trustees </h2>
            <div className="team team-members">
              <Row>
                <Col md="3">
                  <Card onClick={() => setModalBio3(true)}>
                    <img
                      alt="..."
                      src={require("assets/img/Seinye.png").default}
                    ></img>
                    <h4 className="mt-3" onClick={() => setModalBio3(true)}>
                      Dr (Mrs) Seinye O. B. Lulu-Briggs
                    </h4>
                    <p className="text-info">Chair</p>
                  </Card>
                </Col>

                {/* <Col md="3">
                  <Card>
                    <img
                      alt="..."
                      src={require("assets/img/Ganiyu.jpg").default}
                    ></img>
                    <h4 className="mt-3">Ganiyu Leslie Laniyan MBE</h4>
                  </Card>
                </Col> */}

                {/* <Col md="3">
                  <Card>
                    <img
                      alt="..."
                      src={require("assets/img/TopeSolola.jpg").default}
                    ></img>
                    <h4 className="mt-3 mb-5">Tope Solola</h4>
                  </Card>
                </Col> */}
                <Col md="3">
                  <Card onClick={() => setModalBio1(true)}>
                    <img
                      alt="Mrs Jumoke Ali Baba"
                      src={require("assets/img/MrsJumokeAliBaba.jpg").default}
                    ></img>

                    <h4 className="mt-3" onClick={() => setModalBio1(true)}>
                      Mrs Jumoke Ali Baba
                    </h4>
                    <p className="text-info">Trustee</p>
                  </Card>
                </Col>
                {/* <Col md="3">
                  <Card onClick={() => setModalBio1(true)}>
                    <img
                      alt="..."
                      src={require("assets/img/IboromaAkpana.jpg").default}
                    ></img>
                    <h4
                      className="mt-3 mb-5"
                      onClick={() => setModalBio1(true)}
                    >
                      Iboroma Akpana
                    </h4>
                  </Card>
                </Col> */}
                <Col md="3">
                  <Card onClick={() => setModalBio2(true)}>
                    <img
                      alt="Ms. Aima Obriango"
                      src={require("assets/img/AimaObriango.jpg").default}
                    ></img>
                    <h4 className="mt-3" onClick={() => setModalBio2(true)}>
                      Ms. Aima Obriango
                    </h4>
                    <p className="text-info">Trustee</p>
                  </Card>
                </Col>
              </Row>
            </div>

            {/* Operation */}
            <h2 className="title">Staff Members</h2>
            <div className="team team-members">
              <Row>
                <Col md="3">
                  <Card>
                    <img
                      alt="Ahana, Princess Osinachi"
                      src={
                        require("assets/img/staff/Princess Ahana.jpg").default
                      }
                    ></img>
                    <h4 className="mt-3">Ahana, Princess Osinachi</h4>
                    <p className="text-info">
                      Assistant Coordinator of Programmes & Health Programme
                      Officer
                    </p>
                  </Card>
                </Col>

                <Col md="3">
                  <Card>
                    <img
                      alt="Amachree, David"
                      src={
                        require("assets/img/staff/David Amachree.jpg").default
                      }
                    ></img>
                    <h4 className="mt-3">Amachree, David</h4>
                    <p className="text-info">Media Officer</p>
                  </Card>
                </Col>

                <Col md="3">
                  <Card>
                    <img
                      alt="Ben, George"
                      src={require("assets/img/staff/Ben George.jpg").default}
                    ></img>
                    <h4 className="mt-3">Ben, George</h4>
                    <p className="text-info">Procurement /Logistics Officer</p>
                  </Card>
                </Col>

                <Col md="3">
                  <Card>
                    <img
                      alt="Elekima Dorcas"
                      src={
                        require("assets/img/staff/Dorcas Elekima.jpg").default
                      }
                    ></img>
                    <h4 className="mt-3">Elekima Dorcas</h4>
                    <p className="text-info">
                      Education & Scholarship Program Officer
                    </p>
                  </Card>
                </Col>

                <Col md="3">
                  <Card>
                    <img
                      alt="Frey, Ronald Dadoki"
                      src={require("assets/img/staff/Ronald Frey.jpg").default}
                    ></img>
                    <h4 className="mt-3">Frey, Ronald Dadoki</h4>
                    <p className="text-info">
                      {" "}
                      Care for Life Programme Officer
                    </p>
                  </Card>
                </Col>

                <Col md="3">
                  <Card>
                    <img
                      alt="Georgewill, Micah"
                      src={
                        require("assets/img/staff/Micah Georgewill.jpg").default
                      }
                    ></img>
                    <h4 className="mt-3">Georgewill, Micah</h4>
                    <p className="text-info">Head Driver</p>
                  </Card>
                </Col>

                <Col md="3">
                  <Card>
                    <img
                      alt="Iyalla, Gloria Ezekiel"
                      src={
                        require("assets/img/staff/Gloria Iyalla.jpg").default
                      }
                    ></img>
                    <h4 className="mt-3">Iyalla, Gloria Ezekiel</h4>
                    <p className="text-info">
                      Special Projects Programme Officer
                    </p>
                  </Card>
                </Col>

                <Col md="3">
                  <Card>
                    <img
                      alt="Izidor, Emmanuel Okwulogwu"
                      src={
                        require("assets/img/staff/Izidor Emmanuel.jpg").default
                      }
                    ></img>
                    <h4 className="mt-3">Izidor, Emmanuel Okwulogwu</h4>
                    <p className="text-info">Cashier/ Finance Assistant</p>
                  </Card>
                </Col>

                <Col md="3">
                  <Card>
                    <img
                      alt="Jack, Stanley"
                      src={require("assets/img/staff/Stanley Jack.jpg").default}
                    ></img>
                    <h4 className="mt-3">Jack, Stanley</h4>
                    <p className="text-info">Logistics Assistant</p>
                  </Card>
                </Col>

                <Col md="3">
                  <Card>
                    <img
                      alt="John, Grac"
                      src={require("assets/img/staff/Grace John.jpg").default}
                    ></img>
                    <h4 className="mt-3">John, Grace</h4>
                    <p className="text-info">Office Maintenance Assistant</p>
                  </Card>
                </Col>

                <Col md="3">
                  <Card>
                    <img
                      alt="Megwei, Emmanuel Chiedu"
                      src={
                        require("assets/img/staff/Emmanuel Miegwei.jpg").default
                      }
                    ></img>
                    <h4 className="mt-3">Megwei, Emmanuel Chiedu</h4>
                    <p className="text-info">
                      Monitoring and Evaluation Officer
                    </p>
                  </Card>
                </Col>

                <Col md="3">
                  <Card>
                    <img
                      alt="Matthew, Emmanuel"
                      src={
                        require("assets/img/staff/Emmanuel Mathew.jpg").default
                      }
                    ></img>
                    <h4 className="mt-3">Matthew, Emmanuel</h4>
                    <p className="text-info">Driver</p>
                  </Card>
                </Col>

                <Col md="3">
                  <Card>
                    <img
                      alt="Ndianabasi, Archibong Ikeme"
                      src={
                        require("assets/img/staff/Ndianabasi Archibong.jpg")
                          .default
                      }
                    ></img>
                    <h4 className="mt-3">Ndianabasi, Archibong Ikeme</h4>
                    <p className="text-info">Office Attendant</p>
                  </Card>
                </Col>
                <Col md="3">
                  <Card>
                    <img
                      alt="Ndifreke Matthew"
                      src={
                        require("assets/img/staff/Ndifeke Mattew.jpg").default
                      }
                    ></img>
                    <h4 className="mt-3">Ndifreke, Matthew</h4>
                    <p className="text-info">Driver</p>
                  </Card>
                </Col>
                {/*  */}
                <Col md="3">
                  <Card>
                    <img
                      alt="Tekena Sukubo"
                      src={
                        require("assets/img/staff/Tekena Sukubo.jpg").default
                      }
                    ></img>
                    <h4 className="mt-3">Sukubo Tekena</h4>
                    <p className="text-info">
                      Head of Finance and Administration
                    </p>
                  </Card>
                </Col>

                <Col md="3">
                  <Card>
                    <img
                      alt="West Gabriel"
                      src={require("assets/img/staff/Gabriel West.jpg").default}
                    ></img>
                    <h4 className="mt-3"> West, Gabriel</h4>
                    <p className="text-info">Health Progamme Assistant </p>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        {/* Modal Bio */}
        <Modal
          className="modal-lg"
          modalClassName="bd-example-modal-lg"
          toggle={() => setModalBio1(false)}
          isOpen={modalBio1}
        >
          <div className="modal-header">
            <h4 className="modal-title" id="exampleModalBio1Label">
              Mrs Jumoke Ali Baba
            </h4>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => setModalBio1(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              Mrs. Sunjuba Olajumoke Ali Baba is a retired civil servant who
              currently resides in Kaduna, Nigeria. She was an educator for many
              years, teaching in numerous secondary schools in Lagos, Kano, and
              Kaduna. She also worked as a Vice Principal and later Principal at
              Government Girls Secondary School, Kawo, Kaduna. Mrs. Ali Baba
              earned a Master’s degree in Education from Ahmadu Bello University
              and a Bachelor of Science in Geology from the University of
              London. Sunjuba Ali Baba sits on the Board of the National
              Commission of Museum and Monuments and is the founder and first
              president of the Kaduna Horticultural Society. An Egba native from
              Ogun State, she is the Grand Matron of Abeokuta Descendants Union
              of Nigeria (ADUN), Kaduna Branch. She is also a member of various
              Christian organizations and societies, including Full Gospel
              Business Men’s Fellowship International. Mrs. Ali Baba’s hobbies
              include gardening, writing, reading, and swimming. She is a proud
              mother of four and grandmother of seven.
            </p>
          </div>
        </Modal>
        {/* Modal bio */}
        <Modal
          className="modal-lg"
          modalClassName="bd-example-modal-lg"
          toggle={() => setModalBio2(false)}
          isOpen={modalBio2}
        >
          <div className="modal-header">
            <h4 className="modal-title" id="exampleModalBio1Label">
              Ms. Aima Obriango
            </h4>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => setModalBio2(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              Ms. Aima Obriango is a Management Professional specializing in
              transition management for medium to large-scale enterprises in
              varying industries. Currently, she is a Senior Consultant at Rios
              Partners, a management consulting firm in the Washington DC area.
              Her professional skillset lies in corporate positioning, human
              resource management and marketing strategy. Aima is committed to
              philanthropy, with a passion for early childhood education. She
              has been a Foundation trustee since 2007.
            </p>
            <p>
              Ms. Obriango earned a Master’s degree in Construction Management
              from Stanford University and a Bachelor of Engineering (B.Eng),
              Civil Engineering from University College London (UCL). She is
              also a certified Project Management Professional and earned a
              Customer Driven Marketing certificate from the Wharton Business
              School in Dubai, UAE.
            </p>
          </div>
        </Modal>
        {/* Modal bio */}
        <Modal
          className="modal-lg"
          modalClassName="bd-example-modal-lg"
          toggle={() => setModalBio3(false)}
          isOpen={modalBio3}
        >
          <div className="modal-header">
            <h4 className="modal-title" id="exampleModalBio1Label">
              Dr. Seinye O.B. Lulu-Briggs
            </h4>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => setModalBio3(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              Dr. Seinye O.B. Lulu-Briggs is the Chairman of the O.B.
              Lulu-Briggs Foundation, the organisation she founded in 2001 in
              the name of her late husband to honour, celebrate and
              institutionalise his philanthropic legacy. She is also the
              Executive Chairman/CEO of Moni Pulo Limited, a pioneer fully
              indigenous upstream player in Nigeria’s oil and gas industry. Dr
              Mrs. Lulu-Briggs manages several other ventures, including La Sien
              Bottling Company, Soliyama Limited, and Rachael Hotel. She has
              been recognized as one of the Top 20 Women of Influence in Africa,
              among numerous other awards for her success in entrepreneurship.{" "}
            </p>
            <p>
              Dr. Mrs. Lulu-Briggs has served on numerous boards, including
              Transnational Corporation Plc (Transcorp Group). She is a Paul
              Harris Fellow of the Rotary Club International, Fellow of the
              Institute of Directors, Nigeria and a member of the Institute of
              Data Processing Management, London. She was honoured with a Doctor
              of Science degree (Honoris Causa) by the University of Port
              Harcourt in 2015 for her business acumen and philanthropy.{" "}
            </p>
            <p>
              Seinye Lulu-Briggs is an author and a servant of God Almighty,
              with a passion for teaching the undiluted word of God and
              extending love to her fellow man. She is a Pastor at the Chapel of
              God International Worship Centre, a non-denominational gathering
              based in Port Harcourt, Nigeria. Her ministry extends globally
              through her online fellowship, Time Out with the Lord.
            </p>
          </div>
        </Modal>

        <DarkFooter />
      </div>
    </>
  );
}

export default Team;
