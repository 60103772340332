import React from "react";
import "../home/style.css";
// reactstrap components
import { Container, Row, Col, Card, UncontrolledCollapse } from "reactstrap";
import Switch from "react-bootstrap-switch";
// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";

function PrivacyPolicy() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="section section-campaign">
          <Container>
            <Row>
              <Col lg="12" sm="6">
                <h3>Customize your preference</h3>
                <p>
                  We use several types of cookies to run our website, for
                  analytics and to personalise the content you see, like showing
                  you relevant ads and remembering you every time you visit. If
                  you disable certain cookies, you may not be able to use some
                  of the functions of our website or enjoy a personalised
                  experience. You can choose the type of cookies we use here.
                </p>
                <Card className="p-5">
                  <div>
                    <Switch
                      offColor=""
                      offText=""
                      onColor=""
                      onText=""
                    ></Switch>
                    <span className="font-weight-bold h5">
                      Partner Marketing Cookies
                    </span>

                    <p>
                      These cookies can be used to display third party’s (our
                      partners) advertisements that may be relevant to you and
                      measure the effectiveness of marketing activities that our
                      partners run in cooperation with us.
                      <br />
                      <a
                        color="primary"
                        href="#collapseExampleEc"
                        id="linkTogglerMc"
                        style={{
                          fontSize: 13,
                          borderBottom: "1px solid #f96332",
                        }}
                      >
                        Show more
                      </a>
                      <UncontrolledCollapse toggler="#linkTogglerMc">
                        These cookies are set by third parties (our partners) to
                        track your interests, including information about the
                        products and services you are interested in, or
                        purchase, on our Platforms. This information can be used
                        to make the third parties’ advertising displayed on
                        other Platforms more relevant to you and measure the
                        effectiveness of, and optimize, marketing activities
                        that our partners run in cooperation with us. Please
                        note that our partners are also data controllers of the
                        personal information collected and processed via these
                        cookies and they will use this information for their own
                        purposes. These third parties, however, use non-directly
                        identifiable information (which does not include, for
                        example, your name, address, email address or telephone
                        number). We encourage you to read the privacy statements
                        and relevant notices of these third parties.
                      </UncontrolledCollapse>
                    </p>
                  </div>
                  <div>
                    <Switch
                      offColor=""
                      offText=""
                      onColor=""
                      onText=""
                    ></Switch>
                    <span className="font-weight-bold h5">
                      Essential Cookies
                    </span>

                    <p>
                      These cookies are required to perform basic website
                      functionality.
                      <br />
                      <a
                        color="primary"
                        href="#collapseExampleEc"
                        id="linkTogglerEc"
                        style={{
                          fontSize: 13,
                          borderBottom: "1px solid #f96332",
                        }}
                      >
                        Show more
                      </a>
                      <UncontrolledCollapse toggler="#linkTogglerEc">
                        These cookies are essential for the operation of our
                        Platforms. They include, for example, cookies which
                        enable you to log into secure areas of our websites;
                        progress or complete a booking; or make use of our other
                        online services. These cookies also help us understand
                        how users navigate and interact with our website, which
                        means we can continue to improve the overall experience
                        without identifying or targeting any individual user.
                      </UncontrolledCollapse>
                    </p>
                  </div>
                  <div>
                    <Switch
                      offColor=""
                      offText=""
                      onColor=""
                      onText=""
                    ></Switch>
                    <span className="font-weight-bold h5">
                      Functional Cookies
                    </span>

                    <p>
                      These cookies allow us to personalise content for you and
                      improve your experience.
                      <br />
                      <a
                        color="primary"
                        href="#collapseFc"
                        id="linkTogglerFc"
                        style={{
                          fontSize: 13,
                          borderBottom: "1px solid #f96332",
                        }}
                      >
                        Show more
                      </a>
                      <UncontrolledCollapse toggler="#linkTogglerFc">
                        These cookies are used to recognise a user when they
                        return to our Platforms. This enables us to personalise
                        content for you, greet you by name, provide your
                        Skywards details if you are registered, remember your
                        preferences (for example, your choice of language or
                        region) and provide assistance where necessary.
                      </UncontrolledCollapse>
                    </p>
                  </div>
                  <div>
                    <Switch
                      offColor=""
                      offText=""
                      onColor=""
                      onText=""
                    ></Switch>
                    <span className="font-weight-bold h5">
                      Marketing Cookies
                    </span>

                    <p>
                      These cookies are used to display ads that may be relevant
                      to you.
                      <br />
                      <a
                        color="primary"
                        href="#collapseExampleMc"
                        id="linkTogglerMc"
                        style={{
                          fontSize: 13,
                          borderBottom: "1px solid #f96332",
                        }}
                      >
                        Show more
                      </a>
                      <UncontrolledCollapse toggler="#linkTogglerMc">
                        These cookies record your visit to our websites, the
                        pages you have visited and actions you have taken. We
                        will use this information to make our advertising
                        displayed on other platforms (by third party advertising
                        providers) more relevant to you. We may share this
                        information with third parties for this purpose, for
                        example, we may serve you a relevant advertisement based
                        on your browsing history with our platforms. We also use
                        third party cookies so we can personalise our
                        advertisements to you and to help us analyse our
                        customers' behaviour. Our third party advertising
                        providers set anonymous cookies on other websites that
                        we advertise on. If you receive one of those cookies, we
                        may then use it to identify you as having visited that
                        site if you later visit our Platforms. We then
                        personalise our advertisements to you based on this
                        information.
                      </UncontrolledCollapse>
                    </p>
                  </div>
                  <p>
                    You can change your preference at any time. For more
                    infromation please see our <a href="#">cookie policy</a>.
                  </p>
                </Card>
              </Col>
              <Col>
                <button className="btn btn-primary">Back</button>
                <button className="btn btn-secondary">Save & Continue</button>
              </Col>
            </Row>
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default PrivacyPolicy;
